import React from 'react';
import { Modal, Form } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from './../../components/selectitem';
import TableUtils from './../../components/tableutils';

const DangerActionLeaderUserEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;

    return (
        <Modal
            visible={visible}
            title='隐患整改负责人'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <SelectItem title='区域/装置' data={data} method='district' property='districtId' textProperty='districtName'/>
                <SelectItem title='隐患级别' data={data} method='enum/dangerLevel' property='dangerLevel' textProperty='dangerLevelName'/>
                <SelectItem title='整改负责人' data={data} method='user' property='actionLeaderUserId' textProperty='actionLeaderUserName'/>
                <SelectItem title='现场负责人' data={data} method='user' property='actionSceneUserId' textProperty='actionSceneUserName'/>
                <InputItem type='number' title='顺序' property='sort'/>
                <InputItem type='checkbox' title='可用' property='usable'/>
            </Form>
        </Modal>
    );
};

export default DangerActionLeaderUserEdit;
