import React from 'react';
import { Table, Form, Row, Button } from 'antd';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.metro.css';
import JqxSplitter from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxsplitter';
import Resizable from 'react-component-resizable';
import './../../../style.less';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import BaseController from '../../components/basecontroller';
import PatrolDetailList from './patroldetaillist';
import InputItem from '../../components/inputitem';
import SelectItem from '../../components/selectitem';

class PatrolList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            controller: new BaseController(this, 'patrol'),
            selectedRowId: 0,
            tabIndex: 0,
            data: null,
            contentHeight: 100,
            contentWidth: 600,
            leftWidth: null,
            currentPage: 1,
            pageSize: 20,
            current: null
        };
        this.columns = [
            { dataIndex: 'startTime', title: '开始时间', align: 'center', width: 150, render: TableUtils.dateTimeFormat },
            { dataIndex: 'endTime', title: '结束时间', align: 'center', width: 150, render: TableUtils.dateTimeFormat },
            { dataIndex: 'isError', title: '出错', align: 'center', width: 65, render: TableUtils.boolFormat },
            { dataIndex: 'count', title: '总数', align: 'center', width: 65, render: TableUtils.numberFormat },
            { dataIndex: 'completeCount', title: '完成数量', align: 'center', width: 80, render: TableUtils.numberFormat },
            { dataIndex: 'errorCount', title: '出错数量', align: 'center', width: 80, render: TableUtils.numberFormat },
            { dataIndex: 'patrolPlanName', title: '巡检计划', align: 'center', width: 150 },
            { dataIndex: 'patrolUserName', title: '巡检人', align: 'center', width: 80 },
            { dataIndex: 'workTimeName', title: '巡检班次', align: 'center', width: 80 },
            {}
        ];
    }

    componentDidMount() {
        this.state.controller.load();
    }

    loadChildren = (newId, id) => {
        if (this.state.data !== null) {
            const current = TableUtils.getRowById(this.state.data.datas, newId);
            if (!SysUtils.isEmpty(current)) {
                this.state.current = current;
                this.state.controller.loadChildren();
            }
        }
    }

    getMainWidth = ()=> {
        return SysUtils.isEmpty(this.state.leftWidth) ? (this.state.contentWidth - 500) : (this.state.contentWidth - this.state.leftWidth);
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading/>;
        }
        const me = this;
        const data = me.state.data;
        const controller = me.state.controller;
        const rowSelection = {
            type: 'radio',
            columnWidth: '30px',
            fixed: true,
            selectedRowKeys: [this.state.selectedRowId],
            onChange: selectedRowKeys => {
                if (selectedRowKeys.length > 0) {
                    this.loadChildren(selectedRowKeys[0], this.state.selectedRowId);
                }
                me.setState({
                    selectedRowId: selectedRowKeys.length > 0 ? selectedRowKeys[0] : 0
                });
            }
        };
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableUsedHeight(me.state) - 74 },
            pagination: TableUtils.getPagination(this.state.controller, this.state),
            columns: this.columns,
            size: 'small',
            rowKey: 'id',
            rowSelection,
            dataSource: data.datas,
            title: () => {
                return TableUtils.getSingleActionBar(this.state.controller);
            },
            onRow: (record, index) => ({
                onClick: () => {
                    const oldId = me.state.selectedRowId;
                    this.setState({
                        selectedRowId: record.id
                    });
                    this.loadChildren(record.id, oldId);
                },
            }),
            rowClassName: (record, index) => {
                if (record.isError) {
                    return 'x-grid3-row-alt-red';
                } else if (!record.completed) {
                    return 'x-grid3-row-alt-yellow';
                }
            }
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                this.setState({
                    contentHeight: e.height,
                    contentWidth: e.width
                });
                if (SysUtils.isEmpty(this.state.leftWidth)) {
                    this.setState({
                        leftWidth: 500
                    });
                }
            }}>
                <JqxSplitter
                    width={ me.state.contentWidth }
                    height={ me.state.contentHeight }
                    panels={[{ size: me.getMainWidth(), min: 100, collapsible: false }, { size: SysUtils.isEmpty(me.state.leftWidth) ? 500 : me.state.leftWidth, min: 100, collapsible: false }]}
                >
                    <Resizable className={'splitter-panel'} style={{height: SysUtils.getContentHeight()}} onResize={e=>{
                        if (!SysUtils.isEmpty(me.state.leftWidth)) {
                            me.setState({
                                leftWidth: me.state.contentWidth - e.width
                            });
                        }
                    }}>
                        <Form ref={r => me.searchForm = r} className='ant-two-column-form' style={{marginRight: 50}}>
                            <Row style={{ flex: 2, marginTop: 4 }}>
                                <SelectItem title='巡检人' method='user' isSearch property='patrolUserId' textProperty='patrolUserName'/>
                                <InputItem type='date' title='巡检开始日期' isSearch property='startTime'/>
                            </Row>
                            <Row style={{ flex: 2}}>
                                <InputItem type='date' title='巡检结束日期' isSearch property='endTime'/>
                                <div className='div-container'>
                                    <Button style={{ margin: 'auto 10px' }} onClick={e=>{
                                        me.searchForm.validateFields().then(values => {
                                            const conditions = TableUtils.getSearchConditions(values, {
                                                patrolUserId: 'eq',
                                                startTime: 'ge',
                                                endTime: 'le'
                                            });
                                            me.state.conditions = conditions;
                                            controller.search();
                                        });
                                    }}>查询</Button>
                                </div>
                            </Row>
                        </Form>
                        <Table {...renderProps} />
                    </Resizable>
                    <div className='splitter-panel'>
                        <PatrolDetailList parentController={me.state.controller}/>
                    </div>
                </JqxSplitter>
            </Resizable>
        );
    }
}

export default PatrolList;
