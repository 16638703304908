import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class TeamStore extends BaseStore {
    method = 'team';
}

const teamStore = new TeamStore();
export default teamStore;
