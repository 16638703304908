import React from 'react';
import './../../../style.less';
import { Table } from 'antd';
import Resizable from 'react-component-resizable';
import TreeToolBar from '../../components/treetoolbar';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import BaseTreeController from '../../components/basetreecontroller';
import MenuEdit from './menuedit';

class MenuList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editVisible: false,
            controller: new BaseTreeController(this, 'menu'),
            contentHeight: 100,
            data: null
        };
        this.columns = [
            { dataIndex: 'name', title: '标题', width: 250 },
            { dataIndex: 'menuKey', title: 'KEY', align: 'center', width: 150 },
            { dataIndex: 'iconName', title: '图标', align: 'center', width: 120 },
            { dataIndex: 'url', title: '路径', align: 'center', width: 400 },
            { dataIndex: 'sort', title: '顺序', align: 'center', width: 65 },
            { dataIndex: 'usable', title: '可用', align: 'center', width: 65, render: TableUtils.boolFormat },
            { },
            TableUtils.getTreeAction(this.state.controller)
        ];
    }

    componentDidMount() {
        this.state.controller.load();
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;

        const items = [];
        this.state.data.datas.forEach(item => {
            item.key = item.id;
        });
        this.state.data.datas.forEach(item1 => {
            if (item1.superId === 0) {
                item1.children = null;
                items.push(item1);
                this.state.data.datas.forEach(item2 => {
                    if (item1.id === item2.superId) {
                        item2.children = null;
                        if (item1.children === null) {
                            item1.children = [];
                        }
                        item1.children.push(item2);
                        this.state.data.datas.forEach(item3 => {
                            if (item2.id === item3.superId) {
                                if (item2.children === null) {
                                    item2.children = [];
                                }
                                item3.children = null;
                                item2.children.push(item3);
                            }
                        });
                    }
                });
            }
        });
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            defaultExpandAllRows: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableNoPaginationUsedHeight() },
            pagination: false,
            columns: me.columns,
            size: 'small',
            dataSource: items,
            title: () => {
                return TableUtils.getActionBar(me.state.controller);
            }
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                me.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                <MenuEdit
                    controller={me.state.controller}
                    visible={me.state.editVisible}
                    data={me.state.current}
                    onOk={me.state.controller.handleOk}
                    onCancel={me.state.controller.handleCancel}
                />
                <Table {...renderProps} />
            </Resizable>
        );
    }
}

export default MenuList;
