import React from 'react';
import './../../../style.less';
import { Table, Form, Row, Button, Divider, Tooltip, Upload, Modal } from 'antd';
import {
    SyncOutlined
} from '@ant-design/icons';
import Resizable from 'react-component-resizable';
import Lightbox from 'react-image-lightbox';
import ToolBar from '../../components/toolbar';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import RequestUtils from '../../components/requestutils';
import BaseController from '../../components/basecontroller';
import Icon from './../../components/icon';
import DangerActionDelayApplyEdit from './dangeractiondelayapplyedit';
import Toast from '../../components/toast';
import ImageView from './../../components/imageview';
import InputItem from '../../components/inputitem';
import SelectItem from '../../components/selectitem';

class DangerActionList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editVisible: false,
            imageVisible: false,
            imagePath: '',
            controller: new BaseController(this, 'DangerAction'),
            data: null,
            contentHeight: 100,
            currentPage: 1,
            pageSize: 20,
            current: null,
            params: {
                districtId: 0,
                dangerTypeId: 0,
                dangerSubTypeId: 0
            }
        };
        const uploadprops = (record) => {
            return {
                accept: 'image/jpeg',
                beforeUpload: file => {
                    const parmas = {
                        id: record.id,
                        domain: 'DangerAction',
                        imagePath: record.dangerActionImagePath
                    };
                    RequestUtils.uploadFile('imageFunction/pcUploadImage', parmas, file, result=>{
                        if (result.result) {
                            record.dangerActionImagePath = result.data;
                        }
                        result.result ? Toast.success('图片上传成功') : Toast.error('图片上传失败');
                    });
                },
                showUploadList: false
            };
        };
        const fontSize = '14px';
        this.columns = [
            { dataIndex: 'districtName', title: '区域装置', align: 'center', width: 80 },
            { dataIndex: 'storeyName', title: '层数', align: 'center', width: 80 },
            { dataIndex: 'tag', title: 'TAG号', align: 'center', width: 80 },
            { dataIndex: 'checkDate', title: '排查日期', align: 'center', width: 150, render: TableUtils.dateTimeFormat },
            { dataIndex: 'dangerContent', title: '隐患内容', align: 'center', width: 100 },
            { dataIndex: 'dangerCheckImagePath', title: '整改前照片', align: 'center', width: 90, render: (cellData, rowData)=>{
                return SysUtils.isEmpty(cellData) ? <span>无图片</span> : <span className='blue pointer' onClick={e=>{
                    this.setState({
                        imageVisible: true,
                        imagePath: cellData
                    });
                }}>查看图片</span>;
            } },
            { dataIndex: 'actionTypeName', title: '整改类型', align: 'center', width: 80 },
            { dataIndex: 'actionTime', title: '整改日期', align: 'center', width: 150, render: TableUtils.dateTimeFormat },
            { dataIndex: 'dangerActionImagePath', title: '整改后照片', align: 'center', width: 90, render: (cellData, rowData)=>{
                return SysUtils.isEmpty(cellData) ? <span>无图片</span> : <span className='blue pointer' onClick={e=>{
                    this.setState({
                        imageVisible: true,
                        imagePath: cellData
                    });
                }}>查看图片</span>;
            } },
            { dataIndex: 'actionUserName', title: '整改人', align: 'center', width: 80 },
            { dataIndex: 'actionLimitDate', title: '整改期限', align: 'center', width: 90, render: TableUtils.dateFormat },
            { dataIndex: 'applyActionLimitDate', title: '申请延迟期限', align: 'center', width: 150, render: TableUtils.dateFormat },
            { dataIndex: 'completed', title: '整改完成', align: 'center', width: 80, render: TableUtils.boolFormat },
            { dataIndex: 'overdue', title: '超时', align: 'center', width: 65, render: TableUtils.boolFormat },
            { dataIndex: 'dangerTypeName', title: '隐患大类', align: 'center', width: 80 },
            { dataIndex: 'dangerSubTypeName', title: '隐患小类', align: 'center', width: 80 },
            { dataIndex: 'dangerName', title: '隐患标准', align: 'center', width: 150 },
            { dataIndex: 'dangerLevelName', title: '隐患级别', align: 'center', width: 80 },
            { dataIndex: 'dangerSource', title: '隐患来源', align: 'center', width: 80 },
            { dataIndex: 'checkUserNames', title: '排查人', align: 'center', width: 80 },
            { dataIndex: 'inputUserName', title: '填报人', align: 'center', width: 80 },
            { dataIndex: 'actionLeaderUserName', title: '整改负责人', align: 'center', width: 90 },
            { dataIndex: 'actionSceneUserName', title: '现场负责人', align: 'center', width: 90 },
            { dataIndex: 'remark', title: '备注', align: 'center', width: 150 },
            {},
            {
                title: '数据操作',
                align: 'center',
                width: 120,
                fixed: 'right',
                key: 'operation',
                render: (text, record) => (
                    <div style={{ width: 120 }}>
                        <Tooltip placement='top' title='整改'>
                            <span style={{ cursor: 'pointer' }} onClick={e => {
                                //this.state.controller.edit(record);
                            }}>
                                <Icon type='edit' style={{ color: '#FF3D3D', fontSize: fontSize }} />
                            </span>
                        </Tooltip>
                        <Divider type='vertical' />
                        <Tooltip placement='top' title='延期申请'>
                            <span style={{ cursor: 'pointer' }} onClick={e => {
                                //this.state.controller.edit(record);
                            }}>
                                <Icon type='clock_circle' style={{ color: '#FF3D3D', fontSize: fontSize }} />
                            </span>
                        </Tooltip>
                        <Divider type='vertical' />
                        <Upload {...uploadprops(record)}>
                            <Tooltip placement='top' title='上传整改前图片'>
                                <span style={{ cursor: 'pointer' }}>
                                    <Icon type='upload' style={{ color: '#1890FF', fontSize: fontSize }} />
                                </span>
                            </Tooltip>
                        </Upload>
                    </div>
                )
            }
        ];
    }

    componentDidMount() {
        this.state.controller.load();
    }

    getConditions = (callback) => {
        this.searchForm.validateFields().then(values => {
            const conditions = TableUtils.getSearchConditions(values, {
                dangerLevel: 'eq',
                actionType: 'eq',
                dangerSourceId: 'eq',
                districtId: 'eq',
                storeyId: 'eq',
                dangerTypeId: 'eq',
                dangerSubTypeId: 'eq',
                dangerId: 'eq',
                completed: 'eq',
                endTime: 'le',
                checkDateFrom: 'false',
                checkDateTo: 'false',
                actionTimeFrom: 'false',
                actionTimeTo: 'false'
            });
            if (!SysUtils.isEmpty(values.checkDateFrom)) {
                const condition = {name: 'checkDate', value: values.checkDateFrom, relation: 'ge'};
                conditions.push(condition);
            }
            if (!SysUtils.isEmpty(values.checkDateTo)) {
                const condition = {name: 'checkDate', value: values.checkDateTo, relation: 'le'};
                conditions.push(condition);
            }
            if (!SysUtils.isEmpty(values.actionTimeFrom)) {
                const condition = {name: 'actionTime', value: values.actionTimeFrom, relation: 'ge'};
                conditions.push(condition);
            }
            if (!SysUtils.isEmpty(values.actionTimeTo)) {
                const condition = {name: 'actionTime', value: values.actionTimeTo, relation: 'le'};
                conditions.push(condition);
            }
            callback(conditions);
        });
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;
        const controller = me.state.controller;
        const data = this.state.data;
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableUsedHeight(me.state) - 185 },
            pagination: TableUtils.getPagination(me.state.controller, me.state),
            columns: me.columns,
            size: 'small',
            dataSource: data.datas,
            title: () => {
                return <div className='div-container'>
                    <span style={{flex: 1, margin: 'auto 0'}}>
                        <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                            controller.refresh();
                        }}>
                            <SyncOutlined style={{ padding: ' 0 5px' }} />刷新
                        </span>
                        <Divider type='vertical' />
                    </span>
                </div>;
            },
            rowClassName: (record, index) => {
                if (record.actionTime === null && record.overdue) {
                    return 'x-grid3-row-alt-red';
                } else if (record.applyActionLimitDate !== null) {
                    return 'x-grid3-row-alt-yellow';
                }
            }
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                this.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                <Form ref={r => me.searchForm = r} className='ant-two-column-form' style={{marginRight: 50}}>
                    <Row style={{marginTop: 4}}>
                        <SelectItem title='隐患级别' method='enum/dangerLevel' property='dangerLevel' textProperty='dangerLevelName'/>
                        <SelectItem title='整改类型' method='enum/actionType' property='actionType' textProperty='actionTypeName'/>
                        <SelectItem title='隐患来源' method='dangerSource' property='dangerSourceId' textProperty='dangerSourceName'/>
                        <SelectItem title='区域装置' method='district' property='districtId' textProperty='districtName' onChange={value=>{
                            me.state.params.districtId = value;
                            me.searchForm.setFieldsValue({
                                storeyId: null,
                                storeyName: ''
                            });
                            this.forceUpdate();
                        }}/>
                    </Row>
                    <Row>
                        <SelectItem title='层数' method='storey' property='storeyId' filterKey={me.state.params.districtId} textProperty='storeyName'/>
                        <SelectItem title='隐患大类' method='dangerType' property='dangerTypeId' textProperty='dangerTypeName' onChange={value=>{
                            me.state.params.dangerTypeId = value;
                            me.state.params.dangerSubTypeId = 0;
                            me.searchForm.setFieldsValue({
                                dangerSubTypeId: null,
                                dangerSubTypeName: '',
                                dangerId: null,
                                dangerName: ''
                            });
                            this.forceUpdate();
                        }}/>
                        <SelectItem title='隐患小类' method='dangerSubType' property='dangerSubTypeId' filterKey={me.state.params.dangerTypeId} textProperty='dangerSubTypeName' onChange={value=>{
                            me.state.params.dangerSubTypeId = value;
                            me.state.params.dangerId = 0;
                            me.searchForm.setFieldsValue({
                                dangerId: null,
                                dangerName: ''
                            });
                            this.forceUpdate();
                        }}/>
                        <SelectItem title='隐患标准' method='danger' property='dangerId' filterKey={me.state.params.dangerSubTypeId} textProperty='dangerName'/>
                    </Row>
                    <Row>
                        <InputItem title='TAG号' property='tag'/>
                        <SelectItem title='整改负责人' method='user' property='actionLeaderUserId' textProperty='actionLeaderUserName'/>
                        <SelectItem title='整改人' method='user' property='actionUserId' textProperty='actionUserName'/>
                        <InputItem type='bool' title='整改完成' property='completed'/>
                    </Row>
                    <Row>
                        <InputItem type='date' title='排查开始日期' property='checkDateFrom'/>
                        <InputItem type='date' title='排查结束日期' property='checkDateTo'/>
                        <InputItem type='date' title='整改开始日期' property='actionTimeFrom'/>
                        <InputItem type='date' title='整改结束日期' property='actionTimeTo'/>
                    </Row>
                    <Row style={{flex: 4}}>
                        <InputItem type='bool' title='超时' property='overdue'/>
                        <span style={{flex: 2}}/>
                        <div className='div-container'>
                            <Button style={{ margin: 'auto 10px' }} onClick={e=>{
                                /*me.searchForm.validateFields().then(values => {
                                    const conditions = TableUtils.getSearchConditions(values, {
                                        dangerLevel: 'eq',
                                        actionType: 'eq',
                                        dangerSourceId: 'eq',
                                        districtId: 'eq',
                                        storeyId: 'eq',
                                        dangerTypeId: 'eq',
                                        dangerSubTypeId: 'eq',
                                        dangerId: 'eq',
                                        completed: 'eq',
                                        endTime: 'le',
                                        checkDateFrom: 'false',
                                        checkDateTo: 'false',
                                        actionTimeFrom: 'false',
                                        actionTimeTo: 'false'
                                    });
                                    if (!SysUtils.isEmpty(values.checkDateFrom)) {
                                        const condition = {name: 'checkDate', value: values.checkDateFrom, relation: 'ge'};
                                        conditions.push(condition);
                                    }
                                    if (!SysUtils.isEmpty(values.checkDateTo)) {
                                        const condition = {name: 'checkDate', value: values.checkDateTo, relation: 'le'};
                                        conditions.push(condition);
                                    }
                                    if (!SysUtils.isEmpty(values.actionTimeFrom)) {
                                        const condition = {name: 'actionTime', value: values.actionTimeFrom, relation: 'ge'};
                                        conditions.push(condition);
                                    }
                                    if (!SysUtils.isEmpty(values.actionTimeTo)) {
                                        const condition = {name: 'actionTime', value: values.actionTimeTo, relation: 'le'};
                                        conditions.push(condition);
                                    }
                                    me.state.conditions = conditions;
                                    controller.search();
                                });*/
                                me.getConditions(conditions=>{
                                    me.state.conditions = conditions;
                                    controller.search();
                                });
                            }}>查询</Button>
                            <Button style={{ margin: 'auto 10px auto 0' }} onClick={e=>{
                                me.getConditions(conditions=>{
                                    me.state.conditions = conditions;
                                    const params = {
                                        conditions: conditions
                                    };
                                    RequestUtils.print('dangerAction/printList', params, result => {
                                        Toast.closeAll();
                                        if (!result.result) {
                                            Toast.error('打印失败');
                                        } else {
                                            const data = result.data;
                                            const url = RequestUtils.url + '/report/download?name=' + data.name + '&path=' + data.path + '&ext=' + data.ext;
                                            this.setState({
                                                reportVisible: true,
                                                reportTitle: data.caption,
                                                reportUrl: url
                                            });
                                        }
                                    });
                                });
                            }}>打印清单</Button>
                            <Button style={{ margin: 'auto 0' }} onClick={e=>{
                                me.getConditions(conditions=>{
                                    me.state.conditions = conditions;
                                    const params = {
                                        conditions: conditions
                                    };
                                    RequestUtils.print('dangerAction/printPdf', params, result => {
                                        Toast.closeAll();
                                        if (!result.result) {
                                            Toast.error('打印失败');
                                        } else {
                                            const data = result.data;
                                            const url = RequestUtils.url + '/report/download?name=' + data.name + '&path=' + data.path + '&ext=' + data.ext;
                                            this.setState({
                                                reportVisible: true,
                                                reportTitle: data.caption,
                                                reportUrl: url
                                            });
                                        }
                                    });
                                });
                            }}>打印工单</Button>
                        </div>
                    </Row>
                </Form>
                <Modal
                    title='文件下载'
                    visible={this.state.reportVisible}
                    maskClosable={false}
                    footer={<Button onClick={e=>{
                        this.setState({reportVisible: false});
                    }}>关闭</Button>}
                >
                    <a href={this.state.reportUrl} target='_blank'>{this.state.reportTitle}</a>
                </Modal>
                <DangerActionDelayApplyEdit
                    controller={me.state.controller}
                    visible={me.state.editVisible}
                    data={me.state.current}
                    onOk={me.state.controller.handleOk}
                    onCancel={me.state.controller.handleCancel}
                />
                {
                    this.state.imageVisible && <ImageView visible={this.state.imageVisible} imagePath={this.state.imagePath} onCancel={()=>{
                        this.setState({imageVisible: false});
                    }}/>
                }
                <Table {...renderProps} />
            </Resizable>
        );
    }
}

export default DangerActionList;
