import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class ExamineTypeStore extends BaseStore {
    method = 'examineType';
}

const examineTypeStore = new ExamineTypeStore();
export default examineTypeStore;
