import React from 'react';
import { Table, Form, Row, Button, Divider, Tooltip, Upload } from 'antd';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.metro.css';
import JqxSplitter from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxsplitter';
import Resizable from 'react-component-resizable';
import './../../../style.less';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import Icon from './../../components/icon';
import TableUtils from '../../components/tableutils';
import BaseController from '../../components/basecontroller';
import ExamineEdit from './examineedit';
import ExamineDetailList from './examinedetaillist';
import ImageView from './../../components/imageview';
import InputItem from '../../components/inputitem';
import SelectItem from '../../components/selectitem';

class ExamineList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editVisible: false,
            imageVisible: false,
            imagePath: '',
            controller: new BaseController(this, 'examine'),
            selectedRowId: 0,
            tabIndex: 0,
            data: null,
            contentHeight: 100,
            contentWidth: 600,
            leftWidth: null,
            currentPage: 1,
            pageSize: 20,
            current: null
        };
        const uploadprops = (record) => {
            return {
                accept: 'image/jpeg',
                beforeUpload: file => {
                    const parmas = {
                        id: record.id,
                        domain: 'Examine',
                        imagePath: record.imagePath
                    };
                    RequestUtils.uploadFile('imageFunction/pcUploadImage', parmas, file, result=>{
                        if (result.result) {
                            record.imagePath = result.data;
                        }
                        result.result ? Toast.success('图片上传成功') : Toast.error('图片上传失败');
                    });
                },
                showUploadList: false
            };
        };
        const fontSize = '14px';
        this.columns = [
            { dataIndex: 'examineDirectionName', title: '检查方向', align: 'center', width: 100 },
            { dataIndex: 'checkDate', title: '检查日期', align: 'center', width: 90, render: TableUtils.dateFormat },
            { dataIndex: 'examineCheckTypeName', title: '检查类型', align: 'center', width: 120 },
            { dataIndex: 'participantNames', title: '参加人员', align: 'center', width: 150 },
            { dataIndex: 'participantImagePath', title: '人员照片', align: 'center', width: 90, render: (cellData, rowData)=>{
                return SysUtils.isEmpty(cellData) ? <span>无图片</span> : <span className='blue pointer' onClick={e=>{
                    this.setState({
                        imageVisible: true,
                        imagePath: cellData
                    });
                }}>查看图片</span>;
            } },
            { dataIndex: 'inputUserName', title: '录入人', align: 'center', width: 80 },
            { dataIndex: 'completed', title: '完成', align: 'center', width: 65, render: TableUtils.boolFormat },
            { dataIndex: 'remark', title: '备注', align: 'center', width: 200 },
            {},
            {
                title: '数据操作',
                align: 'center',
                width: 110,
                fixed: 'right',
                key: 'operation',
                render: (text, record) => (
                    <div style={{ width: 110 }}>
                        <Tooltip placement='top' title='编辑'>
                            <span style={{ cursor: 'pointer' }} onClick={e => {
                                this.state.controller.edit(record);
                            }}>
                                <Icon type='edit' style={{ color: '#1890ff', fontSize: fontSize }} />
                            </span>
                        </Tooltip>
                        <Divider type='vertical' />
                        <Tooltip placement='top' title='删除'>
                            <span style={{ cursor: 'pointer' }} onClick={e => {
                                this.state.controller.delete(record);
                            }}>
                                <Icon type='delete' style={{ color: '#FF3D3D', fontSize: fontSize }} />
                            </span>
                        </Tooltip>
                        <Divider type='vertical' />
                        <Upload {...uploadprops(record)}>
                            <Tooltip placement='top' title='上传图片'>
                                <span style={{ cursor: 'pointer' }}>
                                    <Icon type='upload' style={{ color: '#1890ff', fontSize: fontSize }} />
                                </span>
                            </Tooltip>
                        </Upload>
                    </div>
                )
            }
        ];
    }

    componentDidMount() {
        this.state.controller.load();
    }

    loadChildren = (newId, id) => {
        if (this.state.data !== null) {
            const current = TableUtils.getRowById(this.state.data.datas, newId);
            if (!SysUtils.isEmpty(current)) {
                this.state.current = current;
                this.state.controller.loadChildren();
            }
        }
    }

    getMainWidth = ()=> {
        return SysUtils.isEmpty(this.state.leftWidth) ? (this.state.contentWidth - 500) : (this.state.contentWidth - this.state.leftWidth);
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading/>;
        }
        const me = this;
        const data = me.state.data;
        const controller = me.state.controller;
        const rowSelection = {
            type: 'radio',
            columnWidth: '30px',
            fixed: true,
            selectedRowKeys: [this.state.selectedRowId],
            onChange: selectedRowKeys => {
                if (selectedRowKeys.length > 0) {
                    this.loadChildren(selectedRowKeys[0], this.state.selectedRowId);
                }
                me.setState({
                    selectedRowId: selectedRowKeys.length > 0 ? selectedRowKeys[0] : 0
                });
            }
        };
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableUsedHeight(me.state) - 111 },
            pagination: TableUtils.getPagination(this.state.controller, this.state),
            columns: this.columns,
            size: 'small',
            rowKey: 'id',
            rowSelection,
            dataSource: data.datas,
            title: () => {
                return TableUtils.getSingleActionBar(this.state.controller);
            },
            onRow: (record, index) => ({
                onClick: () => {
                    const oldId = me.state.selectedRowId;
                    this.setState({
                        selectedRowId: record.id
                    });
                    this.loadChildren(record.id, oldId);
                },
            })
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                this.setState({
                    contentHeight: e.height,
                    contentWidth: e.width
                });
                if (SysUtils.isEmpty(this.state.leftWidth)) {
                    this.setState({
                        leftWidth: 500
                    });
                }
            }}>
                <JqxSplitter
                    width={ me.state.contentWidth }
                    height={ me.state.contentHeight }
                    panels={[{ size: me.getMainWidth(), min: 100, collapsible: false }, { size: SysUtils.isEmpty(me.state.leftWidth) ? 500 : me.state.leftWidth, min: 100, collapsible: false }]}
                >
                    <Resizable className={'splitter-panel'} style={{height: SysUtils.getContentHeight()}} onResize={e=>{
                        if (!SysUtils.isEmpty(me.state.leftWidth)) {
                            me.setState({
                                leftWidth: me.state.contentWidth - e.width
                            });
                        }
                    }}>
                        <Form ref={r => me.searchForm = r} className='ant-two-column-form' style={{marginRight: 50}}>
                            <Row style={{flex: 2, marginTop: 4 }}>
                                <SelectItem title='检查方向' data={data} method='examineDirection' property='examineDirectionId' textProperty='examineDirectionName'/>
                                <div className='div-container'>
                                    <Button style={{ margin: 'auto 10px' }} onClick={e=>{
                                        me.searchForm.validateFields().then(values => {
                                            const conditions = [];
                                            if (!SysUtils.isEmpty(values.examineDirectionId)) {
                                                const condition = {name: 'examineDirectionId', value: values.examineDirectionId, relation: 'eq'};
                                                conditions.push(condition);
                                            }
                                            if (!SysUtils.isEmpty(values.examineCheckType)) {
                                                const condition = {name: 'examineCheckType', value: values.examineCheckType, relation: 'eq'};
                                                conditions.push(condition);
                                            }
                                            if (!SysUtils.isEmpty(values.checkDateFrom)) {
                                                const condition = {name: 'checkDate', value: values.checkDateFrom, relation: 'ge'};
                                                conditions.push(condition);
                                            }
                                            if (!SysUtils.isEmpty(values.checkDateTo)) {
                                                const condition = {name: 'checkDate', value: values.checkDateTo, relation: 'le'};
                                                conditions.push(condition);
                                            }
                                            me.state.conditions = conditions;
                                            controller.search();
                                        });
                                    }}>查询</Button>
                                </div>
                            </Row>
                            <Row style={{ flex: 2}}>
                                <SelectItem title='检查类型' data={data} method='enum/examineCheckType' property='examineCheckType' textProperty='examineCheckTypeName'/>
                                <span style={{flex: 1}}/>
                            </Row>
                            <Row>
                                <Row style={{ flex: 2}}>
                                    <InputItem type='date' title='检查开始日期' isSearch property='checkDateFrom'/>
                                    <InputItem type='date' title='检查结束日期' isSearch property='checkDateTo'/>
                                </Row>
                            </Row>
                        </Form>
                        <ExamineEdit
                            controller={me.state.controller}
                            visible={me.state.editVisible}
                            data={me.state.current}
                            onOk={me.state.controller.handleOk}
                            onCancel={me.state.controller.handleCancel}
                        />
                        {
                            this.state.imageVisible && <ImageView visible={this.state.imageVisible} imagePath={this.state.imagePath} onCancel={()=>{
                                this.setState({imageVisible: false});
                            }}/>
                        }
                        <Table {...renderProps}/>
                    </Resizable>
                    <div className='splitter-panel'>
                        <ExamineDetailList parentController={me.state.controller}/>
                    </div>
                </JqxSplitter>
            </Resizable>
        );
    }
}

export default ExamineList;
