import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class DeviceTypeStore extends BaseStore {
    method = 'deviceType';
}

const deviceTypeStore = new DeviceTypeStore();
export default deviceTypeStore;
