import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class ExamineCheckTypeStore extends BaseStore {
    method = 'enum/examineCheckType';
}

const examineCheckTypeStore = new ExamineCheckTypeStore();
export default examineCheckTypeStore;
