import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class RoleStore extends BaseStore {
    method = 'role';
}

const roleStore = new RoleStore();
export default roleStore;
