import React, {useState} from 'react';
import { Modal, Form, Row } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from './../../components/selectitem';
import TableUtils from './../../components/tableutils';
import SysUtils from './../../components/sysutils';

const DangerCheckEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;

    const [state, setState] = useState({});

    return (
        <Modal
            visible={visible}
            title='隐患排查'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
            width={800}
        >
            <Form form={form} className='ant-two-column-form' {...TableUtils.formItemLayout()}>
                <Row>
                    <SelectItem title='区域装置' data={data} method='district' property='districtId' textProperty='districtName' onChange={value=>{
                        data.districtId = value;
                        data.storeyId = 0;
                        data.storeyName = '';
                        form.setFieldsValue({
                            storeyId: 0,
                            storeyName: ''
                        });
                        setState({});
                    }}/>
                    <SelectItem title='层数' data={data} method='storey' property='storeyId' filterKey={SysUtils.isEmpty(data) ? 0 : data.districtId} textProperty='storeyName'/>
                </Row>
                <Row>
                    <InputItem title='TAG号' property='tag' required/>
                    <SelectItem title='隐患大类' data={data} method='dangerType' property='dangerTypeId' textProperty='dangerTypeName' onChange={value=>{
                        data.dangerTypeId = value;
                        data.dangerSubTypeId = 0;
                        data.dangerSubTypeName = '';
                        data.dangerId = 0;
                        data.dangerName = '';
                        form.setFieldsValue({
                            dangerSubTypeId: 0,
                            dangerSubTypeName: '',
                            dangerId: 0,
                            dangerName: ''
                        });
                        setState({});
                    }}/>
                </Row>
                <Row>
                    <SelectItem title='隐患小类' data={data} method='dangerSubType' property='dangerSubTypeId' filterKey={SysUtils.isEmpty(data) ? 0 : data.dangerTypeId} textProperty='dangerSubTypeName' onChange={value=>{
                        data.dangerSubTypeId = value;
                        data.dangerId = 0;
                        data.dangerName = '';
                        form.setFieldsValue({
                            dangerId: 0,
                            dangerName: ''
                        });
                        setState({});
                    }}/>
                    <SelectItem title='隐患标准' data={data} method='danger' property='dangerId' filterKey={SysUtils.isEmpty(data) ? 0 : data.dangerSubTypeId} textProperty='dangerName'/>
                </Row>
                <Row>
                    <SelectItem title='隐患级别' data={data} method='enum/dangerLevel' property='dangerLevel' textProperty='dangerLevelName'/>
                    <SelectItem title='隐患来源' data={data} method='dangerSource' property='dangerSourceId' textProperty='dangerSourceName'/>
                </Row>
                <Row>
                    <InputItem type='dateTime' title='排查日期' property='checkDate'/>
                    <SelectItem title='填报人' data={data} method='user' property='inputUserId' textProperty='inputUserName'/>
                </Row>
                <Row>
                    <SelectItem title='整改类型' data={data} method='enum/actionType' property='actionType' textProperty='actionTypeName'/>
                    <InputItem type='date' title='整改期限' property='actionLimitDate'/>
                </Row>
                <Row>
                    <SelectItem title='整改负责人' data={data} method='user' property='actionLeaderUserId' textProperty='actionLeaderUserName'/>
                    <SelectItem title='现场负责人' data={data} method='user' property='actionSceneUserId' textProperty='actionSceneUserName'/>
                </Row>
                <Row>
                    <SelectItem title='整改通知人' data={data} method='user' property='actionNotifyUserId' textProperty='actionNotifyUserName'/>
                    <span style={{flex: 1}}/>
                </Row>
                <Row>
                    <InputItem type='textarea' title='整改内容' property='dangerContent'/>
                </Row>
                <Row>
                    <InputItem type='textarea' title='备注' property='remark'/>
                </Row>
            </Form>
        </Modal>
    );
};

export default DangerCheckEdit;
