import React, { useState } from 'react';
import { Select, Input, Form, Button, Divider, Card } from 'antd';
import {
    UserOutlined,
    LockOutlined,
} from '@ant-design/icons';
import SysUtils from './../../components/sysutils';
import RequestUtils from './../../components/requestutils';
import Toast from './../../components/toast';
import LogonUtils from '../../components/logonutils';

const Logon = (props) => {

    const [form] = Form.useForm();

    const data = {
        projectId: localStorage.getItem('projectId')
    };

    form.setFieldsValue(data);

    const onFinish = (values) =>{
        const params = {
            userName: values.userName,
            password: values.password
        };
        LogonUtils.logon(params, result=>{
            form.resetFields();
            props.history.replace('/');
        });
    };

    return (
        <div style={{ background: '#20222A', width: '100vw', height: '100vh', display: 'flex' }}>
            <span style={{flex: 1}}/>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{flex: 1}}/>
                    <div>
                        <Card title='申安' bordered={false} style={{ width: '400px', padding: '30px', borderRadius: '2px' }} headStyle={{fontSize: '20px'}}>
                            <Form form={form} onFinish={onFinish}>
                                <Form.Item name='userName' rules={[{ required: true, message: '请输入用户名' }]} style={{marginBottom: '20px'}}>
                                    <Input
                                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder='请输入用户名'
                                    />
                                </Form.Item>
                                <Form.Item name='password'>
                                    <Input.Password
                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder='请输入密码'
                                    />
                                </Form.Item>
                                <Divider/>
                                <Form.Item>
                                    <Button type='primary' size='large' style={{fontSize: '18px'}} block htmlType='submit'>
                                        登录
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </div>
                    <span style={{flex: 2}}/>
                </div>
            <span style={{flex: 1}}/>
        </div>
    );
};

export default Logon;