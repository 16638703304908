import React from 'react';
import { Route, Redirect, } from 'react-router-dom';
import LogonUtils from './logonutils';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        LogonUtils.logoned()
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/logon',
                state: { from: props.location }
            }} />
    )} />
);

export default PrivateRoute;