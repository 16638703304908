import React from 'react';
import { Modal, Form } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from '../../components/selectitem';
import TableUtils from './../../components/tableutils';

const ExamineEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;

    return (
        <Modal
            visible={visible}
            title='检查列表'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <SelectItem title='检查方向' data={data} method='examineDirection' property='examineDirectionId' textProperty='examineDirectionName'/>
                <InputItem type='date' title='检查日期' property='checkDate'/>
                <SelectItem title='检查类型' data={data} method='enum/examineCheckType' property='examineCheckType' textProperty='examineCheckTypeName'/>
                <SelectItem title='录入人' data={data} method='user' property='inputUserId' textProperty='inputUserName'/>
                <InputItem type='checkbox' title='完成' property='completed'/>
                <InputItem type='textarea' title='备注' property='remark'/>
            </Form>
        </Modal>
    );
};

export default ExamineEdit;