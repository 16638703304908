import React from 'react';
import './../../../style.less';
import { Table } from 'antd';
import Resizable from 'react-component-resizable';
import DataLoading from '../../components/dataloading';
import Icon from './../../components/icon';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import BaseController from '../../components/basecontroller';
import ExamineDetailEdit from './examinedetailedit';

class ExamineDetailList extends React.Component {

    constructor(props) {
        super(props);
        const { parentController } = this.props;
        this.state = {
            editVisible: false,
            imageVisible: false,
            imagePath: '',
            parentController: null,
            controller: new BaseController(this, 'examineDetail', 'examineId'),
            data: null,
            contentHeight: 100,
            current: null
        };
        parentController.children.push(this.state.controller);
        const fontSize = '14px';
        this.columns = [
            { dataIndex: 'imagePath', title: '现场照片', align: 'center', width: 90, render: (cellData, rowData)=>{
                return SysUtils.isEmpty(cellData) ? <span>无图片</span> : <span className='blue pointer' onClick={e=>{
                    this.setState({
                        imageVisible: true,
                        imagePath: cellData
                    });
                }}>查看图片</span>;
            } },
            { dataIndex: 'examinePathName', title: '检查路径', align: 'center', width: 80 },
            { dataIndex: 'examineContentName', title: '检查内容', align: 'center', width: 150 },
            { dataIndex: 'dutyUserName', title: '负责人', align: 'center', width: 80 },
            { dataIndex: 'targeted', title: '达标', align: 'center', width: 65 },
            { dataIndex: 'needDangerCheck', title: '需整改', align: 'center', width: 65 },
            { dataIndex: 'checkTime', title: '检查时间', align: 'center', width: 150, render: TableUtils.dateTimeFormat },
            { dataIndex: 'actionLimitDate', title: '整改期限', align: 'center', width: 90, render: TableUtils.dateFormat },
            { dataIndex: 'dangerCheckDate', title: '隐患排查日期', align: 'center', width: 150, render: TableUtils.dateTimeFormat },
            { dataIndex: 'remark', title: '备注', align: 'center', width: 200 },
            { dataIndex: 'sort', title: '顺序', align: 'center', width: 65 },
            {},
            TableUtils.getAction(this.state.controller)
        ];
    }

    getParentId = ()=>{
        const { parentController } = this.props;
        if (parentController !== null && parentController.getCurrent()) {
            return parentController.getCurrent().id;
        }
        return 0;
    }

    componentDidMount() {
        const { parentController } = this.props;
        if (this.getParentId() === 0) {
            this.state.controller.setData(this.state.controller.getEmptyData());
        } else {
            this.state.controller.loadChild();
        }
    }

    componentWillUnmount() {
        const { parentController } = this.props;
        parentController.children.remove(this.state.controller);
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;
        const data = this.state.data;
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getDetailTableUsedHeight() },
            pagination: false,
            columns: this.columns,
            size: 'small',
            dataSource: data.datas,
            title: () => {
                return TableUtils.getDetailActionBar(this.state.controller);
            }
        };
        return (
            <Resizable style={{height: SysUtils.getContentHeight()}} onResize={e=>{
                this.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                <ExamineDetailEdit
                    controller={me.state.controller}
                    visible={me.state.editVisible}
                    data={me.state.current}
                    onOk={me.state.controller.handleOk}
                    onCancel={me.state.controller.handleCancel}
                />
                {
                    this.state.imageVisible && <ImageView visible={this.state.imageVisible} imagePath={this.state.imagePath} onCancel={()=>{
                        this.setState({imageVisible: false});
                    }}/>
                }
                <Table {...renderProps}/>
            </Resizable>
        );
    }
}

export default ExamineDetailList;
