import React from 'react';
import { Divider, Tooltip, Input, Button } from 'antd';
import {
    HomeOutlined,
    UserOutlined,
    LockOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    SyncOutlined,
    PlusCircleOutlined,
    CheckOutlined,
    ReloadOutlined,
    KeyOutlined,
    PoweroffOutlined,
    DownOutlined,
    EllipsisOutlined,
    SearchOutlined
} from '@ant-design/icons';
import Icon from './icon';
import moment from 'moment';
import SysUtils from './sysutils';
import RequestUtils from './requestutils';

class TableUtils {
    tabStyle = {
        borderRadius: '2px 2px 0 0',
        padding: '8px 12px',
        marginRight: '2px',
        borderColor: '#F0F0F0',
        borderBottom: '1px sild #FFFFFF',
        background: '#F5F5F5'
    };
    selectTabStyle = {
        borderRadius: '2px 2px 0 0',
        padding: '8px 12px',
        marginRight: '2px',
        borderColor: '#F0F0F0',
        color: '#1890FF',
        fontWeight: 'bold',
        background: '#FFFFFF'
    };
    getAction = (controller, fixed = true, width = 80, buttons = []) => {
        const fontSize = '16px';
        return {
            title: '数据操作',
            align: 'center',
            width: width,
            fixed: fixed ? 'right' : '',
            key: 'operation',
            render: (text, record) => (
                <span style={{ width: `${width}px` }}>
                    <Tooltip placement='top' title='编辑'>
                        <span style={{ cursor: 'pointer' }} onClick={e => {
                            controller.edit(record);
                        }}>
                            <EditOutlined style={{ color: '#1890ff', fontSize: fontSize }} />
                        </span>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip placement='top' title='删除'>
                        <span style={{ cursor: 'pointer' }} onClick={e => {
                            controller.delete(record);
                        }}>
                            <DeleteOutlined style={{ color: '#FF3D3D', fontSize: fontSize }} />
                        </span>
                    </Tooltip>
                    {
                        buttons.map((button, index)=>{
                            return <span key={index}>
                                <Divider type="vertical" />
                                <Tooltip placement='top' title={button.title}>
                                    <span style={{ cursor: 'pointer' }} onClick={e=>{
                                        button.onClick && button.onClick(record);
                                    }}>
                                        <Icon type={button.icon} style={{ color: '#1890ff', fontSize: fontSize }} />
                                    </span>
                                </Tooltip>
                            </span>;
                        })
                    }
                </span>
            )
        };
    }
    getDeleteAction = (controller, fixed = true, width) => {
        const fontSize = '16px';
        return {
            title: '数据操作',
            align: 'center',
            width: 80,
            fixed: fixed ? 'right' : '',
            key: 'operation',
            render: (text, record) => (
                <span style={{ width: 80 }}>
                    <Tooltip placement='top' title='删除'>
                        <span style={{ cursor: 'pointer' }} onClick={e => {
                            controller.delete(record);
                        }}>
                            <DeleteOutlined style={{ color: '#FF3D3D', fontSize: fontSize }} />
                        </span>
                    </Tooltip>
                </span>
            )
        };
    }
    getCustomAction = (fixed = true, width = 80, buttons = [], title = '数据操作') => {
        const fontSize = '16px';
        return {
            title: title,
            align: 'center',
            width: width,
            fixed: fixed ? 'right' : '',
            key: 'operation',
            render: (text, record) => (
                <span style={{ width: '80px' }}>
                    {
                        buttons.map((button, index)=>{
                            return <span key={index}>
                                { index > 0 && <Divider type="vertical" /> }
                                <Tooltip placement='top' title={button.title}>
                                    <span style={{ cursor: 'pointer' }} onClick={e=>{
                                        button.onClick && button.onClick(record);
                                    }}>
                                        <Icon type={button.icon} style={{ color: '#1890ff', fontSize: fontSize }} />
                                    </span>
                                </Tooltip>
                            </span>;
                        })
                    }
                </span>
            )
        };
    }
    getTreeAction = (controller, fixed = true, width = 120, buttons = []) => {
        const fontSize = '16px';
        return {
            title: '数据操作',
            align: 'center',
            width: width,
            fixed: fixed ? 'right' : '',
            key: 'operation',
            render: (text, record) => (
                <span style={{ width: '80px' }}>
                    <Tooltip placement='top' title='新增子节点'>
                        <span style={{ cursor: 'pointer' }} onClick={e => {
                            controller.addChild(record);
                        }}>
                            <PlusCircleOutlined style={{ color: '#1890ff', fontSize: fontSize }} />
                        </span>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip placement='top' title='编辑'>
                        <span style={{ cursor: 'pointer' }} onClick={e => {
                            controller.edit(record);
                        }}>
                            <EditOutlined style={{ color: '#1890ff', fontSize: fontSize }} />
                        </span>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip placement='top' title='删除'>
                        <span style={{ cursor: 'pointer' }} onClick={e => {
                            controller.delete(record);
                        }}>
                            <DeleteOutlined style={{ color: '#FF3D3D', fontSize: fontSize }} />
                        </span>
                    </Tooltip>
                    {
                        buttons.map((button, index)=>{
                            return <span key={index}>
                                <Divider type="vertical" />
                                <Tooltip placement='top' title={button.title}>
                                    <span style={{ cursor: 'pointer' }} onClick={e=>{
                                        button.onClick && button.onClick(record);
                                    }}>
                                        <Icon type={button.icon} style={{ color: '#1890ff', fontSize: fontSize }} />
                                    </span>
                                </Tooltip>
                            </span>;
                        })
                    }
                </span>
            )
        };
    }
    getTableTitleHeight = () => {
        return 16;
    }
    getTableUsedHeight = (state) => {
        /*if (!SysUtils.isEmpty(state) && (SysUtils.isEmpty(state.totalCount) || state.totalCount <= state.pageSize)) {
            return this.getTableNoPaginationUsedHeight();
        }*/
        return 41 + 39 + 57;
    }
    getTableNoPaginationUsedHeight = () => {
        return 41 + 39;
    }
    getDetailTableUsedHeight = () => {
        return 39 + 39;
    }
    getTableUsedWidth = () => {
        return 20;
    }
    getCustomBar = (title, buttons = []) => {
        return <span>
            {
                buttons.map((button, index)=>{
                    return <span key={index}>
                        <Divider type="vertical" />
                        <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={button.onClick}>
                            <Icon type={button.icon} style={{ padding: ' 0 5px' }} />{button.title}
                        </span>
                    </span>;
                })
            }
            {!SysUtils.isEmpty(title) && <Divider type='vertical' />}
            {!SysUtils.isEmpty(title) && <span>{title}</span>}
        </span>;
    }
    getActionSearchBar = (controller, title, buttons = []) => {
        return <div className='div-container'>
            <span style={{flex: 1, margin: 'auto 0'}}>
                <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                    controller.add();
                }}>
                    <PlusOutlined style={{ padding: ' 0 5px' }} />新增
                </span>
                <Divider type='vertical' />
                <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                    controller.refresh();
                }}>
                    <SyncOutlined style={{ padding: ' 0 5px' }} />刷新
                </span>
                <Divider type='vertical' />
                <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                    controller.openSearch();
                }}>
                    <SearchOutlined style={{ padding: ' 0 5px' }} />查询
                </span>
                {
                    buttons.map((button, index)=>{
                        return <span key={index}>
                            <Divider type="vertical" />
                            <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={button.onClick}>
                                <Icon type={button.icon} style={{ padding: ' 0 5px' }} />{button.title}
                            </span>
                        </span>;
                    })
                }
                {!SysUtils.isEmpty(title) && <Divider type='vertical' />}
                {!SysUtils.isEmpty(title) && <span>{title}</span>}
            </span>
            <Input.Search size='small' style={{width: 200}} placeholder='请输入查询条件' onSearch={value => {
                controller.setCurrentPage(1);
                controller.filter = value;
                controller.search();
            }}/>
        </div>;
    }
    getSingleActionBar = (controller, title, buttons = []) => {
        return <div className='div-container'>
            <span style={{flex: 1, margin: 'auto 0'}}>
                <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                    controller.add();
                }}>
                    <PlusOutlined style={{ padding: ' 0 5px' }} />新增
                </span>
                <Divider type='vertical' />
                <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                    controller.refresh();
                }}>
                    <SyncOutlined style={{ padding: ' 0 5px' }} />刷新
                </span>
                {
                    buttons.map((button, index)=>{
                        return <span key={index}>
                            <Divider type="vertical" />
                            <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={button.onClick}>
                                <Icon type={button.icon} style={{ padding: ' 0 5px' }} />{button.title}
                            </span>
                        </span>;
                    })
                }
                {!SysUtils.isEmpty(title) && <Divider type='vertical' />}
                {!SysUtils.isEmpty(title) && <span>{title}</span>}
            </span>
        </div>;
    }
    getActionBar = (controller, title, buttons = []) => {
        return <div className='div-container'>
            <span style={{flex: 1, margin: 'auto 0'}}>
                <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                    controller.add();
                }}>
                    <PlusOutlined style={{ padding: ' 0 5px' }} />新增
                </span>
                <Divider type='vertical' />
                <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                    controller.refresh();
                }}>
                    <SyncOutlined style={{ padding: ' 0 5px' }} />刷新
                </span>
                {
                    buttons.map((button, index)=>{
                        return <span key={index}>
                            <Divider type="vertical" />
                            <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={button.onClick}>
                                <Icon type={button.icon} style={{ padding: ' 0 5px' }} />{button.title}
                            </span>
                        </span>;
                    })
                }
                {!SysUtils.isEmpty(title) && <Divider type='vertical' />}
                {!SysUtils.isEmpty(title) && <span>{title}</span>}
            </span>
            <Input.Search size='small' style={{width: 200}} placeholder='请输入查询条件' onSearch={value => {
                controller.setCurrentPage(1);
                controller.filter = value;
                controller.search();
            }}/>
        </div>;
    }
    getDetailActionBar = (controller, title, buttons = []) => {
        return <span>
            <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                controller.add();
            }}>
                <PlusCircleOutlined style={{ padding: ' 0 5px' }} />新增
            </span>
            <Divider type='vertical' />
            <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                controller.refresh();
            }}>
                <SyncOutlined style={{ padding: ' 0 5px' }} />刷新
            </span>
            {
                buttons.map((button, index)=>{
                    return <span key={index}>
                        <Divider type="vertical" />
                        <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={button.onClick}>
                            <Icon type={button.icon} style={{ padding: ' 0 5px' }} />{button.title}
                        </span>
                    </span>;
                })
            }
            <Divider type='vertical' />
            {!SysUtils.isEmpty(title) && <span>{title}</span>}
        </span>;
    }
    getDetailActionBar = (controller, title, buttons = []) => {
        return <span>
            <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                controller.add();
            }}>
                <PlusCircleOutlined style={{ padding: ' 0 5px' }} />新增
            </span>
            <Divider type='vertical' />
            <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                controller.refresh();
            }}>
                <SyncOutlined style={{ padding: ' 0 5px' }} />刷新
            </span>
            {
                buttons.map((button, index)=>{
                    return <span key={index}>
                        <Divider type="vertical" />
                        <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={button.onClick}>
                            <Icon type={button.icon} style={{ padding: ' 0 5px' }} />{button.title}
                        </span>
                    </span>;
                })
            }
            <Divider type='vertical' />
            {!SysUtils.isEmpty(title) && <span>{title}</span>}
        </span>;
    }
    getRowId = (datas, index) => {
        if (index < 0 || datas.length < index) {
            return 0;
        } else {
            return datas[index].id;
        }
    }
    getRowIndex = (datas, id) => {
        for (let i = 0; i < datas.length; i++) {
            if (datas[i].id === id) {
                return i;
            }
        }
        return -1;
    }
    getRowById = (datas, id) => {
        for (let i = 0; i < datas.length; i++) {
            if (datas[i].id === id) {
                return datas[i];
            }
        }
        return null;
    }
    pagerSizeOptions = () => {
        return [10, 20, 30, 40, 50, 100];
    }
    getPagination = (controller, state) => {
        if (SysUtils.isEmpty(state)) {
            return false;
        }
        return {
            position: 'bottom',
            pageSize: state.pageSize,
            size: 'small',
            hideOnSinglePage: false,
            total: state.totalCount,
            showQuickJumper: true,
            showSizeChanger: true,
            onChange: (currentPage, pageSize) => {
                state.currentPage = currentPage;
                controller.load(true);
            },
            onShowSizeChange: (currentPage, pageSize) => {
                state.pageSize = pageSize;
                controller.load(true);
            }
        };
    }
    getSearchConditions = (values, relations) => {
        const conditions = [];
        for (let d in values) {
            if (SysUtils.isEmpty(values[d])) {
                continue;
            }
            let relation = 'like';
            if (!SysUtils.isEmpty(relations) && !SysUtils.isEmpty(relations[d])) {
                relation = relations[d];
                if (relation === 'false') {
                    continue;
                }
            }
            const condition = {
                name: d,
                value: values[d],
                relation: relation
            };
            conditions.push(condition);
        }
        return conditions;
    }
    searchButtons = (form, onClose, onOk, relations, isCustom = false) => {
        return <div
            style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }}
        >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
                取消
            </Button>
            <Button onClick={e=>{
                form.resetFields();
            }} type='danger' style={{ marginRight: 8 }}>
                清空
            </Button>
            <Button onClick={e=>{
                form.validateFields((err, values) => {
                    onClose && onClose(e);
                    if (isCustom) {
                        onOk && onOk(values);
                    } else {
                        const conditions = [];
                        for (let d in values) {
                            if (SysUtils.isEmpty(values[d])) {
                                continue;
                            }
                            let relation = 'like';
                            if (!SysUtils.isEmpty(relations) && !SysUtils.isEmpty(relations[d])) {
                                relation = relations[d];
                            }
                            const condition = {
                                name: d,
                                value: values[d],
                                relation: relation
                            };
                            conditions.push(condition);
                        }
                        onOk && onOk(conditions);
                    }
                });
            }} type='primary'>
                确定
            </Button>
        </div>;
    }
    transferButtons = (onClose, onSearch, onTransfer) => {
        return <div
            style={{
                width: '100%',
                background: '#fff',
                textAlign: 'right',
            }}
        >
            { !SysUtils.isEmpty(onSearch) && <Button onClick={e=>{
                form.validateFields((err, values) => {
                    onSearch && onSearch(values);
                });
            }} type='primary' style={{ marginRight: 8 }}>
                查询
            </Button> }
            <Button onClick={onClose} style={{ marginRight: 8 }}>
                取消
            </Button>
            { !SysUtils.isEmpty(onSearch) && <Button onClick={e=>{
                form.resetFields();
            }} type='danger' style={{ marginRight: 8 }}>
                清空
            </Button> }
            <Button onClick={e=>{
                onTransfer && onTransfer(e);
            }} type='primary'>
                调用
            </Button>
        </div>;
    }
    emptyText = (content = '数据为空') => {
        return <span>{content}</span>;
    }
    emptyDetailText = (parentId) => {
        return parentId <= 0 ? <span className='red'>请选择主表数据</span> : <span>数据为空</span>;
    }
    formItemLayout = () => {
        return {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 }
        };
    }
    searchFormItemLayout = () => {
        return {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 }
        };
    }
    boolFormat = (cellData, rowData) => {
        if (cellData) {
            return <span style={{ height: '30px', lineHeight: '30px' }}><CheckOutlined /></span>;
        } else {
            return <span style={{ height: '30px', lineHeight: '30px' }}></span>;
        }
    }
    dateFormat = (cellData, rowData) => {
        if (cellData) {
            return <span style={{ height: '30px', lineHeight: '30px' }}>{moment(cellData).format(SysUtils.dateFormat)}</span>;
        } else {
            return <span style={{ height: '30px', lineHeight: '30px' }}></span>;
        }
    }
    dateTimeFormat = (cellData, rowData) => {
        if (cellData) {
            return <span style={{ height: '30px', lineHeight: '30px' }}>{moment(cellData).format('YYYY-MM-DD HH:mm')}</span>;
        } else {
            return <span style={{ height: '30px', lineHeight: '30px' }}></span>;
        }
    }
    numberFormat = (cellData, rowData) => {
        return `${cellData}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
}

const tableUtils = new TableUtils();
export default tableUtils;
