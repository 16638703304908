import React from 'react';
import { Layout, Menu, Dropdown, Tabs, Empty, Divider } from 'antd';
import {
    HomeOutlined,
    UserOutlined,
    LockOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    SyncOutlined,
    PlusCircleOutlined,
    CheckOutlined,
    ReloadOutlined,
    KeyOutlined,
    PoweroffOutlined,
    DownOutlined,
    BankOutlined,
} from '@ant-design/icons';
import { Button } from 'antd/lib/radio';
import Icon from './../../components/icon';
import LogonUtils from '../../components/logonutils';
import SysUtils from '../../components/sysutils';
import Toast from '../../components/toast';
import RequestUtils from './../../components/requestutils';
import Home from './../homes/home';
import PasswordEdit from './../logons/passwordedit';
import CompanySelect from './companyselect';

const { Header, Sider, Content } = Layout;
const TabPane = Tabs.TabPane;
const SubMenu = Menu.SubMenu;

class Index extends React.Component {

    rootSubmenuKeys = [];

    constructor(props) {
        super(props);
        this.newTabIndex = 0;
        const panes = [
            { title: '首页', content: <Home />, key: '-100', closable: false }
        ];
        SysUtils.app = this;
        this.state = {
            activeKey: panes[0].key,
            panes,
            showPasswordEdit: false,
            showCompanySelect: SysUtils.isZero(localStorage.getItem('companyId')),
            menus: null,
            loaded: false
        };
        this.menu = (
            <Menu>
                <Menu.Item onClick={e => {
                    this.setState({
                        showPasswordEdit: true
                    });
                }}>
                    <span style={{ fontSize: '12px' }}>
                        <KeyOutlined />
                        <span style={{ marginLeft: '5px' }}>修改密码</span>
                    </span>
                </Menu.Item>
                <Menu.Item onClick={e => {
                    Toast.confirm('是否退出当前登录', () => {
                        LogonUtils.logout(result => {
                            this.props.history.replace('/');
                        });
                    });
                }}>
                    <span style={{ fontSize: '12px', color: '#FF3D3D' }}>
                        <PoweroffOutlined />
                        <span style={{ marginLeft: '5px' }}>退出登录</span>
                    </span>
                </Menu.Item>
            </Menu>
        );
    }

    componentDidMount() {
        RequestUtils.execute('companyGroup/getCompanys', null, result=>{
            this.state.companys = result.rows;
            this.loadMenus();
        });
    }

    loadMenus = () => {
        RequestUtils.execute('menu/getItems', null, result => {
            this.setState({
                loaded: true,
                menus: result.rows,
                openKeys: result.rows.length === 0 ? [] : [JSON.stringify(result.rows[0].id)]
            });
            result.rows.forEach(item=>{
                this.rootSubmenuKeys.push(JSON.stringify(item.id));
            });
        });
    }

    onChange = (activeKey) => {
        this.setState({ activeKey });
    }

    onEdit = (targetKey, action) => {
        this[action](targetKey);
    };

    remove = (targetKey) => {
        let activeKey = this.state.activeKey;
        let lastIndex;
        this.state.panes.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const panes = this.state.panes.filter(pane => pane.key !== targetKey);
        if (panes.length && activeKey === targetKey) {
            if (lastIndex >= 0) {
                activeKey = panes[lastIndex].key;
            } else {
                activeKey = panes[0].key;
            }
        }
        this.setState({ panes, activeKey });
    };

    removeOther = (targetKey) => {
        const panes = this.state.panes.filter(pane => pane.key === targetKey || !pane.closable);
        this.setState({ panes });
    };

    removeAll = (targetKey) => {
        const panes = this.state.panes.filter(!pane.closable);
        this.setState({ panes, activeKey: '-100' });
    }

    getMenuItemById = (menus, id) => {
        for (let i = 0; i < menus.length; i++) {
            let menu = menus[i];
            if (menu.id === id) {
                return menu;
            } else {
               menu = this.getMenuItemById(menu.children, id);
               if (menu !== null) {
                   return menu;
               }
            }
        }
        return null;
    }

    getPane = (key) => {
        for (let i = 0; i < this.state.panes.length; i++) {
            if (this.state.panes[i].key === key) {
                return this.state.panes[i];
            }
        }
        return null;
    }

    onMenuItemClick = (e) => {
        const item = this.getMenuItemById(this.state.menus, JSON.parse(e.key));
        const pane = this.getPane(e.key);
        if (pane !== null) {
            this.setState({
                activeKey: pane.key
            });
        } else {
            const Com = require('./../' + item.url).default;
            const panes = this.state.panes;
            panes.push({ title: item.name, content: <Com menu={item} />, key: JSON.stringify(item.id), closable: true });
            this.setState({
                activeKey: JSON.stringify(item.id)
            });
        }
    }

    renderTabBar = (props, DefaultTabBar) => {
        // 提取tab信息
        const tabInfo = [];
        props.panels.forEach(item => {
            tabInfo.push({
                key: item.key,
                title: item.props.tab
            });
        });
        const contextMenus = (
            <Menu>
                {props.activeKey !== '-100' && <Menu.Item onClick={e => {
                    this.remove(props.activeKey);
                }}>
                    <div>关闭当前</div>
                </Menu.Item>}
                <Menu.Item onClick={e => {
                    this.removeOther(props.activeKey);
                }}>
                    <div>关闭其他</div>
                </Menu.Item>
                <Menu.Item onClick={e => {
                    this.removeAll(props.activeKey);
                }}>
                    <div >关闭全部</div>
                </Menu.Item>
            </Menu>);
        return (
            <Dropdown overlay={contextMenus} trigger={['contextMenu']}>
                <div style={{ display: 'flex' }}>
                    {
                        tabInfo.map((item, index) => (
                            <div
                                key={item.key}
                                onClick={() => this.setState({ activeKey: item.key })}
                                className={props.activeKey === item.key ? 'activeTab normalTab' : 'normalTab'}
                            >
                                <div style={{ padding: '0 16px' }}>{item.title}</div>
                            </div>
                        ))
                    }
                </div>
            </Dropdown>
        );
    }

    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };

    render() {
        if (!this.state.loaded) {
            return <div style={{ width: '100vw', height: '100vh' }} className='div-container'>
                <div style={{ margin: 'auto', marginTop: '200px' }}>
                    <Empty description='数据加载中...' />
                </div>
            </div>;
        }
        const me = this;
        const key = this.state.selectedMenuKey;
        return (
            <Layout style={{ height: '100vh' }}>
                <Sider
                    style={{ overflow: 'auto', height: '100%', position: 'fixed', left: 0, background: '#FFFFFF' }}
                    trigger={null}
                >
                    <div className='logo' />
                    {
                        this.state.menus && this.state.menus.length > 0 &&
                        <Menu
                            onClick={this.onMenuItemClick}
                            style={{ width: '100%' }}
                            onOpenChange={this.onOpenChange}
                            defaultSelectedKeys={[this.state.menus[0].children.length > 0 ? JSON.stringify(this.state.menus[0].children[0].id) : 0]}
                            defaultOpenKeys={[JSON.stringify(this.state.menus[0].id)]}
                            openKeys={this.state.openKeys}
                            mode='inline'
                            theme='light'>
                            {
                                this.state.menus.map((item, index) => {
                                    return (
                                        <SubMenu key={item.id}
                                            title={
                                                <span>
                                                    <Icon type={item.iconName} />
                                                    <span>{item.name}</span>
                                                </span>
                                            }>
                                            {
                                                item.children.map((item2, index) => {
                                                    return item2.children.length === 0 ? <Menu.Item style={{ marginBottom: 0, marginTop: 0 }} key={item2.id}>{item2.name}</Menu.Item> :
                                                    <SubMenu key={item2.id}
                                                        title={
                                                            <span>{item2.name}</span>
                                                        }>
                                                        {
                                                            item2.children.map((item3, index) => {
                                                                return <Menu.Item style={{ marginBottom: 0, marginTop: 0 }} key={item3.id}>{item3.name}</Menu.Item>;
                                                            })
                                                        }
                                                    </SubMenu>;
                                                })
                                            }
                                        </SubMenu>);
                                })
                            }
                        </Menu>
                    }
                </Sider>
                <Layout style={{ marginLeft: 201 }}>
                    <Content className='div-container' style={{ background: '#FFFFFF', height: '100vh', overflow: 'initial' }}>
                        <Tabs
                            tabBarExtraContent={
                                <div style={{display: 'flex'}}>
                                    <div style={{ marginRight: '20px', cursor: 'pointer', color: '#FF3D3D' }} onClick={e=>{
                                        this.setState({
                                            showCompanySelect: true
                                        });
                                    }}>
                                        <HomeOutlined/>
                                        <span style={{ marginRight: '5px', marginLeft: '5px' }}>{localStorage.getItem('companyName')}</span>
                                    </div>
                                    <Dropdown overlay={this.menu}>
                                        <span className='blue' style={{ marginRight: '10px', cursor: 'pointer' }}>
                                            <UserOutlined />
                                            <span style={{ marginRight: '5px', marginLeft: '5px' }}>{localStorage.getItem('name')}</span>
                                            <DownOutlined />
                                        </span>
                                    </Dropdown>
                                </div>
                            }
                            tabPosition='top'
                            className='flex'
                            onChange={this.onChange}
                            activeKey={this.state.activeKey}
                            type='editable-card'
                            hideAdd
                            tabBarStyle={{ marginBottom: '3px' }}
                            onEdit={this.onEdit}
                        >
                            {this.state.panes.map(pane => <TabPane tab={pane.title} key={pane.key} closable={pane.closable}>{pane.content}</TabPane>)}
                        </Tabs>
                    </Content>
                </Layout>
                <CompanySelect
                    visible={this.state.showCompanySelect}
                    companys={this.state.companys}
                    onCancel={ () => {
                        this.setState({showCompanySelect: false});
                    }}
                    onOk={(company)=>{
                        const params = {
                            companyId: company.id
                        };
                        RequestUtils.execute('logon/setCompanyId', params, result=>{
                            this.setState({showCompanySelect: false});
                            if (result.result) {
                                window.location.reload();
                                Toast.success('公司设置成功');
                            } else {
                                Toast.error('公司设置失败');
                            }
                        });
                    }}
                />
                <PasswordEdit
                    visible={this.state.showPasswordEdit}
                    name={localStorage.getItem('name')}
                    onClose={() => {
                        this.setState({
                            showPasswordEdit: false
                        });
                    }}
                />
            </Layout>
        );
    }
}

export default Index;
