import React from 'react';
import { Modal, Form } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from './../../components/selectitem';
import TableUtils from './../../components/tableutils';

const TeamPatrolPlanEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;

    return (
        <Modal
            visible={visible}
            title='班组巡检计划'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <SelectItem title='班组' data={data} method='team' property='teamId' textProperty='teamName'/>
                <SelectItem title='巡检计划' data={data} method='patrolPlan' property='patrolPlanId' textProperty='patrolPlanName'/>
                <InputItem type='textarea' title='备注' property='remark'/>
                <InputItem type='number' title='顺序' property='sort'/>
                <InputItem type='checkbox' title='可用' property='usable'/>
            </Form>
        </Modal>
    );
};

export default TeamPatrolPlanEdit;
