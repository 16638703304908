import React from 'react';
import './../../../style.less';
import SysUtils from './../../components/sysutils';

class Home extends React.Component {

    render() {
        const { app } = this.props;
        return <div>
        </div>;
    }
}

export default Home;
