import React from 'react';
import './../../../style.less';
import { Table } from 'antd';
import Resizable from 'react-component-resizable';
import ToolBar from '../../components/toolbar';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import UserController from './usercontroller';
import UserEdit from './useredit';
import UserPasswordEdit from './userpasswordedit';

class UserList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editVisible: false,
            showPasswordEdit: false,
            controller: new UserController(this, 'user'),
            data: null,
            contentHeight: 100,
            currentPage: 1,
            pageSize: 20,
            current: null
        };
        this.columns = [
            { dataIndex: 'userName', title: '用户名', align: 'center', width: 120 },
            { dataIndex: 'name', title: '姓名', align: 'center', width: 150 },
            { dataIndex: 'englishName', title: '英文名', align: 'center', width: 150 },
            { dataIndex: 'email', title: '邮箱', align: 'center', width: 150 },
            { dataIndex: 'mobilePhone', title: '手机', align: 'center', width: 150 },
            { dataIndex: 'telephone', title: '电话', align: 'center', width: 150 },
            { dataIndex: 'departmentName', title: '部门', align: 'center', width: 100 },
            { dataIndex: 'teamName', title: '班组', align: 'center', width: 100 },
            { dataIndex: 'roleName', title: '角色', align: 'center', width: 100 },
            { dataIndex: 'sort', title: '顺序', align: 'center', width: 65 },
            { dataIndex: 'usable', title: '可用', align: 'center', width: 65, render: TableUtils.boolFormat },
            {},
            TableUtils.getAction(this.state.controller, true, 120, [{
                title: '修改密码',
                icon: 'key',
                onClick: record=>{
                    this.state.controller.passwordEditForm.setFieldsValue(this.state.current);
                    this.setState({
                        current: record,
                        showPasswordEdit: true
                    });
                }
            }])
        ];
    }

    componentDidMount() {
        this.state.controller.load();
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;
        const data = this.state.data;
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableUsedHeight(me.state) },
            pagination: TableUtils.getPagination(me.state.controller, me.state),
            columns: me.columns,
            size: 'small',
            dataSource: data.datas,
            title: () => {
                return TableUtils.getActionBar(me.state.controller);
            }
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                this.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                <Table {...renderProps} />
                <UserEdit
                    controller={me.state.controller}
                    visible={me.state.editVisible}
                    data={me.state.current}
                    onOk={me.state.controller.handleOk}
                    onCancel={me.state.controller.handleCancel}
                />
                <UserPasswordEdit
                    controller={me.state.controller}
                    visible={me.state.showPasswordEdit}
                    data={me.state.current}
                    onClose={e=>{
                        me.setState({showPasswordEdit: false});
                    }}
                />
            </Resizable>
        );
    }
}

export default UserList;
