import React from 'react';
import Toast from './toast';

class SysUtils {

    dateFormat = 'YYYY-MM-DD';

    isValidDate = (...params) =>{
        var year = 0,
            month = 0,
            date = 0;

        if (params.length === 3) {
            // 2008 3 15
            // '2008' '3' '15'
            year = Number(params[0]);
            month = Number(params[1]);
            date = Number(params[2]);
        } else if (params.length === 2) {
            // '2008-03-15' '-'
            // '2008/3/15' '/'
            // 参数必须为字符串类型，分隔符不能为空字符串或数字
            var str = params[0],
                seperator = params[1],
                dateArray = str.split(seperator);
            year = Number(dateArray[0]);
            month = Number(dateArray[1]);
            date = Number(dateArray[2]);
        } else if (params.length === 1) {
            // '20080315'
            // 20080315
            // 参数必须为8位，且只能为数字
            var str = String(params[0]);
            year = Number(str.slice(0, 4));
            month = Number(str.slice(4, 6));
            date = Number(str.slice(6));
        }
        var dateObj = new Date(year, month - 1, date),
            nYear = dateObj.getFullYear(),
            nMonth = dateObj.getMonth() + 1,
            nDate = dateObj.getDate();

        if (year === nYear && month === nMonth && date === nDate) {
            return true;
        } else {
            return false;
        }
    }

    getContentHeight = (balance = 0)=>{
        return 'calc(100vh - 45px - ' + balance + 'px)';
    };

    getContentWidth= (balance = 0)=>{
        return 'calc(100vw - 200px - ' + balance + 'px)';
    };

    isEmpty = (value) => {
        return value === null || value === '' || value === undefined;
    }
    isZero = (value) => {
        return value === null || value === '' || value === undefined || value === '0' || value === 0;
    }
    getParams = (props) => {
        return props.match.params;
    }
    getOpenId = () => {
        return localStorage.openId;
    }
    debug = (msg) => {
        Toast.error(msg);
    }
    readFile = (file, callback)=>{
        if (file === null) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            if (callback !== null) {
                callback(this.result);
            }
        };
    }
    hexStringToBytes = (str) => {
        var pos = 0;
        var len = str.length;
        if (len % 2 !== 0) {
            return null;
        }
        len /= 2;
        var arrBytes = [];
        for (var i = 0; i < len; i++) {
            var s = str.substr(pos, 2);
            var v = parseInt(s, 16);
            arrBytes.push(v);
            pos += 2;
        }
        return arrBytes;
    }

    bytesToHex = (bytes) => {
        var str = '';
        for (var i = 0; i < bytes.length; i++) {
            var tmp;
            var num = bytes[i];
            if (num < 0) {
                //此处填坑，当byte因为符合位导致数值为负时候，需要对数据进行处理
                tmp = (255 + num + 1).toString(16);
            } else {
                tmp = num.toString(16);
            }
            if (tmp.length === 1) {
                tmp = '0' + tmp;
            }
            str += tmp;
        }
        return str.toUpperCase();
    }

    hexToBytes(str) {
        var pos = 0;
        var len = str.length;
        if (len % 2 !== 0) {
            return null;
        }
        len /= 2;
        var bytes = [];
        for (var i = 0; i < len; i++) {
            var s = str.substr(pos, 2);
            var v = parseInt(s, 16);
            bytes.push(v);
            pos += 2;
        }
        return bytes;
    }
    hexToFloat = (t) => {

        const fillString = (t, c, n, b) => {
            if ((t === '') || (c.length !== 1) || (n <= t.length)) {
                return t;
            }
            let l = t.length;
            for (let i = 0; i < n - l; i++) {
                if (b === true) {
                    t = c + t;
                }
                else {
                    t += c;
                }
            }
            return t;
        };

        t = t.replace(/\s+/g, '');
        if (t === '') {
            return '';
        }
        if (t === '00000000') {
            return '0';
        }
        if ((t.length > 8) || (isNaN(parseInt(t, 16)))) {
            return 'Error';
        }
        if (t.length < 8) {
            t = fillString(t, '0', 8, true);
        }
        t = parseInt(t, 16).toString(2);
        t = fillString(t, '0', 32, true);
        let s = t.substring(0, 1);
        let e = t.substring(1, 9);
        let m = t.substring(9);
        e = parseInt(e, 2) - 127;
        m = '1' + m;
        if (e >= 0) {
            m = m.substr(0, e + 1) + '.' + m.substring(e + 1);
        }
        else {
            m = '0.' + fillString(m, '0', m.length - e - 1, true);
        }
        if (m.indexOf('.') === -1) {
            m = m + '.0';
        }
        let a = m.split('.');
        let mi = parseInt(a[0], 2);
        let mf = 0;
        for (let i = 0; i < a[1].length; i++) {
            mf += parseFloat(a[1].charAt(i)) * Math.pow(2, -(i + 1));
        }
        m = parseInt(mi) + parseFloat(mf);
        if (s === 1) {
            m = 0 - m;
        }
        return m;
    }
};

const sysUtils = new SysUtils();

export default sysUtils;
