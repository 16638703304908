import React from 'react';
import { message, Modal } from 'antd';
import SysUtils from './sysutils';

const confirm = Modal.confirm;

class Toast {

    success = content => {
        message.success(content, 1);
    };

    error = content => {
        message.error(content);
    };

    warning = content => {
        message.warning(content);
    };

    closeAll = ()=>{
        Modal.destroyAll();
    }

    confirm = (content, callback) => {
        confirm({
            title: content,
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk() {
                callback && callback();
            }
        });
    }

    refreshConfirm = (callback, content) => {
        confirm({
            title: SysUtils.isEmpty(content) ? '是否刷新当前数据?' : content,
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk() {
                callback && callback();
            }
        });
    }
    opeatePrompt = (content, callback)=>{
        const hider = message.loading(content, 0);
        callback && callback(hider);
    }
    dataLoading = (callback, content) => {
        const hider = message.loading(SysUtils.isEmpty(content) ? '数据加载中...' : content, 0);
        callback && callback(hider);
    }
    printLoading = (callback, content) => {
        const hider = message.loading(SysUtils.isEmpty(content) ? '打印中...' : content, 0);
        callback && callback(hider);
    }
    dataDeleting = (callback, content) => {
        const hider = message.loading(SysUtils.isEmpty(content) ? '数据删除中...' : content, 0);
        callback && callback(hider);
    }

    dataSaving = (callback, content) => {
        const hider = message.loading(SysUtils.isEmpty(content) ? '数据保存中...' : content, 0);
        callback && callback(hider);
    }

    deleteConfirm = (callback, content) => {
        confirm({
            title: SysUtils.isEmpty(content) ? '是否删除所选数据?' : content,
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk() {
                callback && callback();
            }
        });
    }

    saveConfirm = (callback, content) => {
        confirm({
            title: SysUtils.isEmpty(content) ? '是否保存当前数据?' : content,
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk() {
                callback && callback();
            }
        });
    }

    loadInfo = result =>{
        result.result ? this.success('数据加载成功') : this.error('数据加载失败');
    }

    createInfo = result => {
        if (!result.result) {
            this.error('数据新增失败');
        }
    }

    saveInfo = result =>{
        result.result ? this.success('数据保存成功') : this.error('数据保存失败');
    }

    deleteInfo = result => {
        result.result ? this.success('数据删除成功') : this.error('数据删除失败');
    }
    uploadInfo = result =>{
        result.result ? this.success('文件上传成功') : this.error('文件上传失败');
    }
}

const toast = new Toast();
export default toast;
