import React, {useState} from 'react';
import { Modal, Form } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from './../../components/selectitem';
import TableUtils from './../../components/tableutils';
import SysUtils from './../../components/sysutils';

const DangerEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;

    const [state, setState] = useState({});

    return (
        <Modal
            visible={visible}
            title='隐患标准'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <InputItem title='编号' property='code' required/>
                <InputItem type='textarea' title='名称' property='name' required/>
                <SelectItem title='隐患大类' data={data} method='dangerType' property='dangerTypeId' textProperty='dangerTypeName' onChange={value=>{
                    data.dangerTypeId = value;
                    data.dangerSubTypeId = 0;
                    data.dangerSubTypeName = '';
                    form.setFieldsValue({
                        dangerSubTypeId: 0,
                        dangerSubTypeName: ''
                    });
                    setState({});
                }}/>
                <SelectItem title='隐患小类' data={data} method='dangerSubType' property='dangerSubTypeId' filterKey={SysUtils.isEmpty(data) ? 0 : data.dangerTypeId} textProperty='dangerSubTypeName'/>
                <InputItem type='number' title='顺序' property='sort'/>
                <InputItem type='checkbox' title='可用' property='usable'/>
            </Form>
        </Modal>
    );
};

export default DangerEdit;