import React from 'react';
import 'whatwg-fetch';
import SysUtils from './sysutils';
import Toast from './toast';
import LogonUtils from './logonutils';

class RequestUtils {
    //url = (window.location.protocol + '//' + window.location.hostname + ':8080');
    url = window.location.protocol + '//' + window.location.hostname + (window.location.hostname.indexOf('test') >= 0 ? ':8081' : window.location.hostname.indexOf('xunjian') >= 0 ? '' : ':8080');
    loadingHider = null;
    checkLogon = (result, callback) => {
        if (this.loadingHider !== null) {
            this.loadingHider();
        }
        this.loadingHider = null;
        if (!result.result && result.errorType === 'DisLogon') {
            LogonUtils.clearLogonInfo();
            callback && callback(result);
        } else {
            callback && callback(result);
        }
    }
    showError = (error) => {
        if (this.loadingHider !== null) {
            this.loadingHider();
        }
        this.loadingHider = null;
        Toast.closeAll();
        console.log(error);
        Toast.error('数据操作错误');
    }
    getToken = () => {
        let token = localStorage.getItem('token');
        if (SysUtils.isEmpty(token)) {
            return '';
        }
        return token;
    }
    logonCheck = (callback)=>{
        const params = {};
        params.token = this.getToken();
        fetch(this.url + '/logon/checkLogon', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            callback && callback(result);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    logout = (callback)=>{
        const params = {};
        params.token = this.getToken();
        fetch(this.url + '/logon/logout', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            callback && callback(result);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    dictList = (method, params, callback) => {
        if (params === null) {
            params = {};
        }
        params.token = this.getToken();
        if (method.lastIndexOf('/') === -1) {
            method = method + '/dictList';
        }
        fetch(this.url + '/' + method, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    list = (method, params, callback) => {
        if (params === null) {
            params = {};
        }
        params.token = this.getToken();
        if (method.lastIndexOf('/') === -1) {
            method = method + '/list';
        }
        fetch(this.url + '/' + method, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    search = (method, params, callback) => {
        if (params === null) {
            params = {};
        }
        params.token = this.getToken();
        if (method.lastIndexOf('/') === -1) {
            method = method + '/search';
        }
        fetch(this.url + '/' + method, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    dict = (method, params, callback) => {
        if (params === null) {
            params = {};
        }
        params.token = this.getToken();
        if (method.lastIndexOf('/') === -1) {
            method = method + '/dict';
        }
        fetch(this.url + '/' + method, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    delete = (method, params, callback) => {
        if (params === null) {
            params = {};
        }
        params.token = this.getToken();
        if (method.lastIndexOf('/') === -1) {
            method = method + '/delete';
        }
        fetch(this.url + '/' + method, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    save = (method, params, callback) => {
        if (params === null) {
            params = {};
        }
        params.token = this.getToken();
        if (method.lastIndexOf('/') === -1) {
            method = method + '/save';
        }
        fetch(this.url + '/' + method, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    edit = (method, params, callback) => {
        if (params === null) {
            params = {};
        }
        params.token = this.getToken();
        if (method.lastIndexOf('/') === -1) {
            method = method + '/getById';
        }
        fetch(this.url + '/' + method, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    create = (method, params, callback) => {
        if (params === null) {
            params = {};
        }
        params.token = this.getToken();
        if (method.lastIndexOf('/') === -1) {
            method = method + '/create';
        }
        fetch(this.url + '/' + method, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    execute = (method, params, callback) => {
        if (params === null) {
            params = {};
        }
        params.token = this.getToken();
        fetch(this.url + '/' + method, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    sendCaptcha = (mobilePhone, callback) => {
        const params = {mobilePhone: mobilePhone};
        fetch(this.url + '/user/sendCaptcha', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    uploadFile = (method, params, file, callback) => {
        //Toast.opeatePrompt('文件上传中...');
        SysUtils.readFile(file, base64=>{
            if (params === null) {
                params = {};
            }
            params.token = this.getToken();
            params.base64 = base64;
            fetch(this.url + '/' + method, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(params)
            })
            .then((response) => response.json())
            .then((result) => {
                Toast.closeAll();
                Toast.uploadInfo(result);
                if (callback !== null) {
                    callback(result);
                }
            })
            .catch((error)=> {
                Toast.closeAll();
                this.showError(error);
            });
        });
    }
    getImageUrl = (imagePath) => {
        return this.url + '/imageFunction/getImageByImagePath?imagePath=' + imagePath + '&token=' + this.getToken();
    }
    find = (method, params, callback) => {
        if (params === null) {
            params = {};
        }
        params.token = this.getToken();
        if (method.lastIndexOf('/') === -1) {
            method = method + '/find';
        }
        fetch(this.url + '/' + method, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        })
        .then((response) => response.json())
        .then((result) => {
            this.checkLogon(result, callback);
        })
        .catch((error)=> {
            this.showError(error);
        });
    }
    print = (method, params, callback, action = '') => {
        Toast.printLoading(hider=>{
            this.loadingHider = hider;
            if (SysUtils.isEmpty(params)) {
                params = {};
            }
            params.token = this.getToken();
            if (method.lastIndexOf('/') === -1) {
                method = method + '/print';
            }
            fetch(this.url + '/' + method, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(params)
            })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                if (this.loadingHider !== null) {
                    this.loadingHider();
                }
                if (!result.result) {
                    Toast.error('打印失败');
                } else {
                    callback && callback(result);
                }
            })
            .catch((error)=> {
                this.showError(error);
            });
        });
    }
}

const requestUtils = new RequestUtils();
export default requestUtils;
