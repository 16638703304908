import React from 'react';
import RoleStore from './rolestore';
import DangerTypeStore from './dangertypestore';
import DangerSubTypeStore from './dangersubtypestore';
import DangerStore from './dangerstore';
import DangerSourceStore from './dangersourcestore';
import CompanyStore from './companystore';
import CompanyGroupStore from './companygroupstore';
import DepartmentStore from './departmentstore';
import TeamStore from './teamstore';
import UserStore from './userstore';
import DistrictStore from './districtstore';
import DangerLevelStore from './dangerlevelstore';
import EquipmentStore from './equipmentstore';
import PartStore from './partstore';
import DeviceTypeStore from './devicetypestore';
import PatrolPlanStore from './patrolplanstore';
import RegionStore from './regionstore';
import ExamineDirectionStore from './examinedirectionstore';
import ExamineTypeStore from './examinetypestore';
import ExamineContentStore from './examinecontentstore';
import ExaminePathStore from './examinepathstore';
import ActionTypeStore from './actiontypestore';
import FeedbackTypeStore from './feedbacktypestore';
import ExamineCheckTypeStore from './examinechecktypestore';
import StoreyStore from './storeystore';

class StoreUtils {
    stores = [];
    register() {
        this.stores.push(RoleStore);
        this.stores.push(DangerTypeStore);
        this.stores.push(DangerSubTypeStore);
        this.stores.push(DangerStore);
        this.stores.push(DangerSourceStore);
        this.stores.push(CompanyStore);
        this.stores.push(CompanyGroupStore);
        this.stores.push(DepartmentStore);
        this.stores.push(TeamStore);
        this.stores.push(UserStore);
        this.stores.push(DistrictStore);
        this.stores.push(DangerLevelStore);
        this.stores.push(EquipmentStore);
        this.stores.push(PartStore);
        this.stores.push(DeviceTypeStore);
        this.stores.push(PatrolPlanStore);
        this.stores.push(RegionStore);
        this.stores.push(ExamineDirectionStore);
        this.stores.push(ExamineTypeStore);
        this.stores.push(ExamineContentStore);
        this.stores.push(ExaminePathStore);
        this.stores.push(ActionTypeStore);
        this.stores.push(FeedbackTypeStore);
        this.stores.push(ExamineCheckTypeStore);
        this.stores.push(StoreyStore);
    }
    getStore(method) {
        for (let i = 0; i < this.stores.length; i++) {
            if (this.stores[i].method === method) {
                return this.stores[i];
            }
        }
        return null;
    }
    dict(method, callBack) {
        const store = this.getStore(method, callBack);
        if (store !== null) {
            store.dict(callBack);
        }
    }
    clearDict() {
        for (let i = 0; i < this.stores.length; i++) {
            this.stores[i].dicts = null;
        }
    }
    dictByKey(key, method, callBack) {
        const store = this.getStore(method, callBack);
        if (store !== null) {
            store.dict(key, callBack);
        }
    }
    getItem(method, id) {
        const store = this.getStore(method);
        if (store !== null) {
            return store.getItem(id);
        }
        return null;
    }
    editOrCreateItem(method, id, callBack) {
        const store = this.getStore(method);
        if (store !== null) {
            store.editOrCreateItem(id, callBack);
        }
    }
    editItem(method, id, callBack) {
        const store = this.getStore(method);
        if (store !== null) {
            return store.editItem(id, callBack);
        }
        return null;
    }
    createItem(method, callBack) {
        const store = this.getStore(method);
        if (store !== null) {
            store.createItem(callBack);
        }
    }
    getDicts(method) {
        const store = this.getStore(method);
        if (store !== null) {
            return store.getDicts();
        }
        return [];
    }
    getDictsByKey(key, method) {
        const store = this.getStore(method);
        if (store !== null) {
            return store.getDicts(key);
        }
        return [];
    }
}

const storeUtils = new StoreUtils();
storeUtils.register();
export default storeUtils;
