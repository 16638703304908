import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class PatrolPlanStore extends BaseStore {
    method = 'patrolPlan';
}

const patrolPlanStore = new PatrolPlanStore();
export default patrolPlanStore;
