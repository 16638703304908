import React from 'react';
import './../../../style.less';
import { Table } from 'antd';
import Resizable from 'react-component-resizable';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import BaseController from '../../components/basecontroller';
import ImageView from './../../components/imageview';

class PatrolDetailList extends React.Component {

    constructor(props) {
        super(props);
        const { parentController } = this.props;
        this.state = {
            imageVisible: false,
            imagePath: '',
            parentController: null,
            controller: new BaseController(this, 'patrolDetail', 'patrolId'),
            data: null,
            contentHeight: 100,
            current: null
        };
        parentController.children.push(this.state.controller);
        this.columns = [
            { dataIndex: 'imagePath', title: '图片', align: 'center', width: 80, render: (cellData, rowData)=>{
                return SysUtils.isEmpty(cellData) ? <span>无图片</span> : <span className='blue pointer' onClick={e=>{
                    this.setState({
                        imageVisible: true,
                        imagePath: cellData
                    });
                }}>查看图片</span>;
            } },
            { dataIndex: 'equipmentName', title: '装置', align: 'center', width: 80 },
            { dataIndex: 'partName', title: '区域', align: 'center', width: 80 },
            { dataIndex: 'deviceTag', title: 'TAG号', align: 'center', width: 80 },
            { dataIndex: 'deviceName', title: '设备', align: 'center', width: 80 },
            { dataIndex: 'reading', title: '读数', align: 'center', width: 65 },
            { dataIndex: 'deviceStatusName', title: '设备状态', align: 'center', width: 80 },
            { dataIndex: 'fiveStandardName', title: '5S', align: 'center', width: 65 },
            { dataIndex: 'stockStatusName', title: '库存状态', align: 'center', width: 80 },
            { dataIndex: 'unitName', title: '单位', align: 'center', width: 65 },
            { dataIndex: 'isError', title: '出错', align: 'center', width: 65, render: TableUtils.boolFormat },
            { dataIndex: 'minReading', title: '最小读数', align: 'center', width: 80 },
            { dataIndex: 'maxReading', title: '最大读数', align: 'center', width: 80 },
            {},
        ];
    }

    getParentId = ()=>{
        const { parentController } = this.props;
        if (parentController !== null && parentController.getCurrent()) {
            return parentController.getCurrent().id;
        }
        return 0;
    }

    componentDidMount() {
        const { parentController } = this.props;
        if (this.getParentId() === 0) {
            this.state.controller.setData(this.state.controller.getEmptyData());
        } else {
            this.state.controller.loadChild();
        }
    }

    componentWillUnmount() {
        const { parentController } = this.props;
        parentController.children.remove(this.state.controller);
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;
        const data = this.state.data;
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getDetailTableUsedHeight() },
            pagination: false,
            columns: this.columns,
            size: 'small',
            dataSource: data.datas,
            title: () => {
                return TableUtils.getDetailActionBar(this.state.controller);
            },
            rowClassName: (record, index) => {
                if (record.isError) {
                    return 'x-grid3-row-alt-red';
                } else if (!record.completed) {
                    return 'x-grid3-row-alt-yellow';
                }
            }
        };
        return (
            <Resizable style={{height: SysUtils.getContentHeight()}} onResize={e=>{
                this.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                {
                    this.state.imageVisible && <ImageView visible={this.state.imageVisible} imagePath={this.state.imagePath} onCancel={()=>{
                        this.setState({imageVisible: false});
                    }}/>
                }
                <Table {...renderProps}/>
            </Resizable>
        );
    }
}

export default PatrolDetailList;
