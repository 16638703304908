import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class CompanyStore extends BaseStore {
    method = 'company';
}

const companyStore = new CompanyStore();
export default companyStore;
