import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class DangerStore extends BaseStore {
    method = 'danger';

    getDicts = (key)=>{
        if (SysUtils.isEmpty(this.dicts) || SysUtils.isEmpty(key)) {
            return [];
        }
        const result = [];
        this.dicts.forEach(item => {
            if (item.parentId === key) {
                result.push(item);
            }
        });
        return result;
    }
}

const dangerStore = new DangerStore();
export default dangerStore;
