import React from 'react';
import './../../../style.less';
import { Table, Select, Divider } from 'antd';
import {
    SaveOutlined
} from '@ant-design/icons';
import Resizable from 'react-component-resizable';
import TreeToolBar from '../../components/treetoolbar';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import RequestUtils from '../../components/requestutils';
import TableUtils from '../../components/tableutils';
import BaseTreeController from '../../components/basetreecontroller';
import Toast from '../../components/toast';

const { Option } = Select;

class RolePermissionList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editVisible: false,
            controller: new BaseTreeController(this, 'rolePermission'),
            contentHeight: 100,
            data: null,
            roleId: 0,
            roles: [],
            selectedRowKeys: []
        };
        this.columns = [
            { dataIndex: 'name', title: '标题', width: 250 }, {}
        ];
    }

    componentDidMount() {
        RequestUtils.dict('role', null, result=>{
            this.state.roles = result.rows;
            RequestUtils.search('menu/getRoleItems', null, result=>{
                if (result.result) {
                    this.state.controller.setData(result);
                }
            });
        });
    }

    showModal = () => {
        this.view.setState({ editVisible: true });
    };

    saveFormRef = formRef => {
        this.state.controller.setFormRef(formRef);
    };

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;
        this.state.data.datas.forEach(item => {
            if (item.children.length === 0) {
                item.children = null;
            }
        });
        const items = this.state.data.datas;
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: selectedRowKeys => {
                this.setState({
                    selectedRowKeys: selectedRowKeys
                });
            }
        };
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            defaultExpandAllRows: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableNoPaginationUsedHeight() },
            pagination: false,
            columns: this.columns,
            size: 'small',
            dataSource: items,
            rowSelection,
            title: () => {
                return <span>
                    <Select style={{width: '200px'}} size='small' showSearch={true} placeholder='请选择设置权限的角色' onChange={value=>{
                        if (SysUtils.isEmpty(value)) {
                            return;
                        }
                        RequestUtils.execute('rolePermission/getByRoleId', { roleId: JSON.parse(value) }, result=>{
                            const menuKeys = [];
                            result.rows.forEach(item=>{
                                menuKeys.push(item.menuKey);
                            });
                            this.setState({
                                roleId: JSON.parse(value),
                                selectedRowKeys: menuKeys
                            });
                        });
                    }}>
                    {
                        this.state.roles.map((item, index)=>{
                            return <Option key={item.id}>{item.name}</Option>;
                        })
                    }
                    </Select>
                    <Divider type='vertical' />
                    <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                        if (SysUtils.isZero(this.state.roleId)) {
                            Toast.error('请先选择设置权限的角色');
                            return;
                        }
                        Toast.confirm('是否保存当前用户权限', ()=>{
                            const params = {
                                roleId: this.state.roleId,
                                menuKeys: this.state.selectedRowKeys
                            };
                            RequestUtils.execute('rolePermission/saveList', params, result=>{
                                if (result.result) {
                                    Toast.success('权限设置成功');
                                } else {
                                    Toast.error('权限设置失败');
                                }
                            });
                        });
                    }}>
                        <SaveOutlined type='save' style={{ padding: ' 0 5px' }} />设置权限
                    </span>
                </span>;
            }
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                this.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                <Table {...renderProps} ref={(c) => { this.table = c; }} />
            </Resizable>
        );
    }
}

export default RolePermissionList;
