import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class DangerLevelStore extends BaseStore {
    method = 'enum/dangerLevel';
}

const dangerLevelStore = new DangerLevelStore();
export default dangerLevelStore;
