import React from 'react';
import { Select, Form, Spin } from 'antd';
import SysUtils from './sysutils';
import StoreUtils from './../stores/storeutils';

const { Option } = Select;

class SelectItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.loadDicts();
    }

    isSearch = () => {
        const { isSearch } = this.props;
        if (SysUtils.isEmpty(isSearch)) {
            return false;
        }
        return isSearch;
    }

    allowClear = () => {
        const { allowClear, required } = this.props;
        if (SysUtils.isEmpty(allowClear) && !required) {
            return true;
        }
        return allowClear;
    }

    getValue() {
        let { value, data, property } = this.props;
        let result = value;
        if (SysUtils.isZero(result)) {
            if (!SysUtils.isEmpty(data) && !SysUtils.isEmpty(property)) {
                result = data[property];
            }
        }
        if (SysUtils.isZero(result)) {
            return undefined;
        }
        return result;
    }

    getText() {
        const { text, data, textProperty } = this.props;
        if (!SysUtils.isEmpty(text)) {
            return text;
        } else {
            if (!SysUtils.isEmpty(data) && !SysUtils.isEmpty(textProperty)) {
                return data[textProperty];
            }
        }
        return text;
    }

    loadDicts = () => {
        const { method, filterKey } = this.props;
        StoreUtils.dict(method, result=>{
            this.setState({
                loading: false
            });
        });
    }

    createInput = () => {
        const { title, mode, method, filterKey, allowClear, required, onChange } = this.props;
        const dicts = SysUtils.isEmpty(filterKey) ? StoreUtils.getDicts(method) : StoreUtils.getDictsByKey(filterKey, method);

        const dictContainZero = () =>{
            for (let i = 0; i < dicts.length; i++) {
                if (dicts[i].id === 0) {
                    return true;
                }
            }
            return false;
        };

        let input = <Select
                allowClear={this.allowClear()}
                showSearch
                mode={mode}
                onChange={onChange}
                filterOption={(input, option) => {
                    return !SysUtils.isEmpty(option.title) && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                notFoundContent={SysUtils.isEmpty(dicts) ? <Spin size='small' /> : null}
                placeholder={'请输入' + title}>
                { !SysUtils.isEmpty(dicts) && !dictContainZero() && <Option key='0' value={0} title={''}>{' '}</Option> }
                {
                    !SysUtils.isEmpty(dicts) && dicts.map((item, i)=>{
                        return <Option key={item.value} value={item.id} title={item.name}>{item.name}</Option>;
                    })
                }
            </Select>;
        return input;
    }

    render() {
        const { title, property, required } = this.props;
        let input = this.createInput();
        if (this.isSearch()) {
            return (<Form.Item label={title} colon={ true } name={property}>
                {input}
            </Form.Item>);
        } else {
            return (<Form.Item label={title} colon={true } name={property} rules={[{ required: required, message: '请选择' + title }]}>
                {input}
            </Form.Item>);
        }
    }
}

export default SelectItem;
