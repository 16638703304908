import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class EquipmentStore extends BaseStore {
    method = 'equipment';
}

const equipmentStore = new EquipmentStore();
export default equipmentStore;
