import React from 'react';
import { Form, InputNumber, Input, Checkbox, DatePicker, Radio } from 'antd';
import {
    EllipsisOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import SysUtils from './sysutils';

class InputItem extends React.Component {

    constructor(props) {
        super(props);
    }

    getValue() {
        const { value, data, property, type } = this.props;
        if (!SysUtils.isEmpty(value)) {
            if (type === 'date') {
                return moment(value);
            }
            return value;
        } else {
            if (!SysUtils.isEmpty(data) && !SysUtils.isEmpty(property) && !SysUtils.isEmpty(data[property])) {
                if (type === 'date') {
                    return moment(data[property]);
                }
                return data[property];
            }
        }
        return value;
    }

    onChange = value=>{
        const { onChange } = this.props;
        onChange && onChange(value);
    }

    normalize = (value, prevValue, prevValues)=> {
        if (type === 'date' || type === 'dateTime') {
            return moment(value);
        }
        return value;
    }

    createInput = () => {
        const { title, items, placeholder, value, readOnly, type, style, precision, addonBefore, addonAfter, prefix, onButtonClick } = this.props;
        let input = null;
        if (type === 'textarea') {
            input = <Input.TextArea placeholder={SysUtils.isEmpty(placeholder) ? ('请输入' + title) : placeholder} style={style} onChange={e=>{
                this.onChange(e.target.value);
            }} />;
        } else if (type === 'checkbox') {
            input = <Checkbox onClick={e=>{
                this.onChange(e.target.checked);
            }}>{title}</Checkbox>;
        } else if (type === 'number') {
            input = <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                placeholder={SysUtils.isEmpty(placeholder) ? ('请输入' + title) : placeholder}
                precision={ precision }
                style={{width: '100%'}}
                onChange={value=>{
                    this.onChange(value);
                }}
                />;
        } else if (type === 'date') {
            input = <DatePicker disabled={readOnly} placeholder={SysUtils.isEmpty(placeholder) ? ('请选择' + title) : placeholder} style={{width: '100%'}} onChange={(date, dateString)=>{
                this.onChange(date);
            }} normalize={this.normalize}/>;
        } else if (type === 'dateTime') {
            input = <DatePicker showTime disabled={readOnly} placeholder={SysUtils.isEmpty(placeholder) ? ('请选择' + title) : placeholder} style={{width: '100%'}} onChange={(date, dateString)=>{
                this.onChange(date);
            }} normalize={this.normalize}/>;
        } else if (type === 'password') {
            input = <Input.Password prefix={prefix} placeholder={SysUtils.isEmpty(placeholder) ? ('请输入' + title) : placeholder} style={style} onChange={e=>{
                this.onChange(e.target.value);
            }}/>;
        } else if (type === 'radio') {
            input = <Radio.Group onChange={e=>{
                this.onChange(e.target.value);
            }}>
            {
                items.map((item, index)=>{
                    return <Radio key={index} value={item.value}>{item.name}</Radio>;
                })
            }
            </Radio.Group>;
        } else if (type === 'bool') {
            input = <Radio.Group onChange={e=>{
                this.onChange(e.target.value);
            }}>
                <Radio value={null}>全部</Radio>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
            </Radio.Group>;
        } else if (!SysUtils.isEmpty(onButtonClick)) {
            input = <Input prefix={prefix} disabled={readOnly} placeholder={SysUtils.isEmpty(placeholder) ? ('请选择' + title) : placeholder} style={style} addonAfter={<span style={{cursor: 'pointer'}} onClick={e=>{
                onButtonClick(e);
            }}><EllipsisOutlined/></span>} onChange={e=>{
                this.onChange(e.target.value);
            }}/>;
        }
        if (input === null) {
            input = <Input prefix={prefix} addonBefore={addonBefore} addonAfter={addonAfter} allowClear={SysUtils.isEmpty(addonAfter) && !readOnly} disabled={readOnly} placeholder={ readOnly ? '' : (SysUtils.isEmpty(placeholder) ? ('请输入' + title) : placeholder)} style={style} onChange={e=>{
                this.onChange(e.target.value);
            }}/>;
        }
        return input;
    }

    isSearch = () => {
        const { isSearch } = this.props;
        if (SysUtils.isEmpty(isSearch)) {
            return false;
        }
        return isSearch;
    }

    render() {
        const { title, property, type, required, addonAfter, addonAfterWidth } = this.props;
        let input = this.createInput();
        const ischeckbox = type === 'checkbox';
        const valuePropName = ischeckbox ? 'checked' : 'value';
        if (SysUtils.isEmpty(property)) {
            return (<Form.Item label={ischeckbox ? ' ' : title} colon={ischeckbox ? false : true }>
                {input}
            </Form.Item>);
        } else if (this.isSearch()) {
            return (<Form.Item label={ischeckbox ? ' ' : title} colon={ischeckbox ? false : true } name={property} valuePropName={valuePropName}>
                {input}
            </Form.Item>);
        } else if (type === 'date' || type === 'dateTime') {
            return (<Form.Item label={ischeckbox ? ' ' : title} colon={ischeckbox ? false : true } style={{paddingTop: type === 'textarea' ? '3px' : null}} name={property} valuePropName={valuePropName} rules={[{ required: required, message: '请输入' + title }]}>
                {input}
            </Form.Item>);
        } else {
            return (<Form.Item label={ischeckbox ? ' ' : title} colon={ischeckbox ? false : true } style={{paddingTop: type === 'textarea' ? '3px' : null}} name={property} valuePropName={valuePropName} rules={[{ required: required, message: '请输入' + title }]} >
                {input}
            </Form.Item>);
        }
    }
}

export default InputItem;