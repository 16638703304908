import React from 'react';
import './../../../style.less';
import { Table, Form, Row, Button, Divider, Tooltip, Upload } from 'antd';
import Resizable from 'react-component-resizable';
import Lightbox from 'react-image-lightbox';
import ToolBar from '../../components/toolbar';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import RequestUtils from '../../components/requestutils';
import BaseController from '../../components/basecontroller';
import Icon from './../../components/icon';
import DangerCheckEdit from './dangercheckedit';
import Toast from '../../components/toast';
import ImageView from './../../components/imageview';
import InputItem from '../../components/inputitem';
import SelectItem from '../../components/selectitem';

class DangerCheckList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editVisible: false,
            imageVisible: false,
            imagePath: '',
            controller: new BaseController(this, 'dangerCheck'),
            data: null,
            contentHeight: 100,
            currentPage: 1,
            pageSize: 20,
            current: null,
            params: {
                districtId: 0,
                dangerTypeId: 0,
                dangerSubTypeId: 0
            }
        };
        const uploadprops = (record) => {
            return {
                accept: 'image/jpeg',
                beforeUpload: file => {
                    const parmas = {
                        id: record.id,
                        domain: 'DangerCheck',
                        imagePath: record.dangerCheckImagePath
                    };
                    RequestUtils.uploadFile('imageFunction/pcUploadImage', parmas, file, result=>{
                        if (result.result) {
                            record.dangerCheckImagePath = result.data;
                        }
                        result.result ? Toast.success('图片上传成功') : Toast.error('图片上传失败');
                    });
                },
                showUploadList: false
            };
        };
        const fontSize = '14px';
        this.columns = [
            { dataIndex: 'districtName', title: '区域装置', align: 'center', width: 80 },
            { dataIndex: 'storeyName', title: '层数', align: 'center', width: 80 },
            { dataIndex: 'tag', title: 'TAG号', align: 'center', width: 80 },
            { dataIndex: 'dangerContent', title: '隐患内容', align: 'center', width: 100 },
            { dataIndex: 'dangerCheckImagePath', title: '整改前照片', align: 'center', width: 90, render: (cellData, rowData)=>{
                return SysUtils.isEmpty(cellData) ? <span>无图片</span> : <span className='blue pointer' onClick={e=>{
                    this.setState({
                        imageVisible: true,
                        imagePath: cellData
                    });
                }}>查看图片</span>;
            } },
            { dataIndex: 'dangerTypeName', title: '隐患大类', align: 'center', width: 80 },
            { dataIndex: 'dangerSubTypeName', title: '隐患小类', align: 'center', width: 80 },
            { dataIndex: 'dangerName', title: '隐患标准', align: 'center', width: 150 },
            { dataIndex: 'dangerSource', title: '隐患来源', align: 'center', width: 80 },
            { dataIndex: 'checkUserNames', title: '排查人', align: 'center', width: 80 },
            { dataIndex: 'checkDate', title: '排查日期', align: 'center', width: 150, render: TableUtils.dateTimeFormat },
            { dataIndex: 'inputUserName', title: '填报人', align: 'center', width: 80 },
            { dataIndex: 'actionLeaderUserName', title: '整改负责人', align: 'center', width: 90 },
            { dataIndex: 'actionSceneUserName', title: '现场负责人', align: 'center', width: 90 },
            { dataIndex: 'actionNotifyUserName', title: '整改通知人', align: 'center', width: 90 },
            { dataIndex: 'actionTypeName', title: '整改类型', align: 'center', width: 80 },
            { dataIndex: 'actionLimitDate', title: '整改期限', align: 'center', width: 90, render: TableUtils.dateFormat },
            { dataIndex: 'dangerActionImagePath', title: '整改后照片', align: 'center', width: 90, render: (cellData, rowData)=>{
                return SysUtils.isEmpty(cellData) ? <span>无图片</span> : <span className='blue pointer' onClick={e=>{
                    this.setState({
                        imageVisible: true,
                        imagePath: cellData
                    });
                }}>查看图片</span>;
            } },
            { dataIndex: 'completed', title: '整改完成', align: 'center', width: 80, render: TableUtils.boolFormat },
            { dataIndex: 'overdue', title: '超时', align: 'center', width: 65, render: TableUtils.boolFormat },
            { dataIndex: 'remark', title: '备注', align: 'center', width: 150 },
            {},
            {
                title: '数据操作',
                align: 'center',
                width: 110,
                fixed: 'right',
                key: 'operation',
                render: (text, record) => (
                    <div style={{ width: 110 }}>
                        <Tooltip placement='top' title='编辑'>
                            <span style={{ cursor: 'pointer' }} onClick={e => {
                                this.state.controller.edit(record);
                            }}>
                                <Icon type='edit' style={{ color: '#1890ff', fontSize: fontSize }} />
                            </span>
                        </Tooltip>
                        <Divider type='vertical' />
                        <Tooltip placement='top' title='删除'>
                            <span style={{ cursor: 'pointer' }} onClick={e => {
                                this.state.controller.delete(record);
                            }}>
                                <Icon type='delete' style={{ color: '#FF3D3D', fontSize: fontSize }} />
                            </span>
                        </Tooltip>
                        <Divider type='vertical' />
                        <Upload {...uploadprops(record)}>
                            <Tooltip placement='top' title='上传整改前图片'>
                                <span style={{ cursor: 'pointer' }}>
                                    <Icon type='upload' style={{ color: '#1890ff', fontSize: fontSize }} />
                                </span>
                            </Tooltip>
                        </Upload>
                    </div>
                )
            }
        ];
    }

    componentDidMount() {
        this.state.controller.load();
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;
        const data = this.state.data;
        const controller = me.state.controller;
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableUsedHeight(me.state) - 148},
            pagination: TableUtils.getPagination(me.state.controller, me.state),
            columns: me.columns,
            size: 'small',
            dataSource: data.datas,
            title: () => {
                return TableUtils.getSingleActionBar(me.state.controller);
            },
            rowClassName: (record, index) => {
                if (record.actionTime === null && record.overdue) {
                    return 'x-grid3-row-alt-red';
                }
            }
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                this.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                <Form ref={r => me.searchForm = r} className='ant-two-column-form' style={{marginRight: 50}}>
                    <Row style={{marginTop: 4}}>
                        <SelectItem title='隐患级别' method='enum/dangerLevel' property='dangerLevel' textProperty='dangerLevelName'/>
                        <SelectItem title='整改类型' method='enum/actionType' property='actionType' textProperty='actionTypeName'/>
                        <SelectItem title='隐患来源' method='dangerSource' property='dangerSourceId' textProperty='dangerSourceName'/>
                        <SelectItem title='区域装置' method='district' property='districtId' textProperty='districtName' onChange={value=>{
                            me.state.params.districtId = value;
                            me.searchForm.setFieldsValue({
                                storeyId: null,
                                storeyName: ''
                            });
                            this.forceUpdate();
                        }}/>
                    </Row>
                    <Row>
                        <SelectItem title='层数' method='storey' property='storeyId' filterKey={me.state.params.districtId} textProperty='storeyName'/>
                        <SelectItem title='隐患大类' method='dangerType' property='dangerTypeId' textProperty='dangerTypeName' onChange={value=>{
                            me.state.params.dangerTypeId = value;
                            me.state.params.dangerSubTypeId = 0;
                            me.searchForm.setFieldsValue({
                                dangerSubTypeId: null,
                                dangerSubTypeName: '',
                                dangerId: null,
                                dangerName: ''
                            });
                            this.forceUpdate();
                        }}/>
                        <SelectItem title='隐患小类' method='dangerSubType' property='dangerSubTypeId' filterKey={me.state.params.dangerTypeId} textProperty='dangerSubTypeName' onChange={value=>{
                            me.state.params.dangerSubTypeId = value;
                            me.state.params.dangerId = 0;
                            me.searchForm.setFieldsValue({
                                dangerId: null,
                                dangerName: ''
                            });
                            this.forceUpdate();
                        }}/>
                        <SelectItem title='隐患标准' method='danger' property='dangerId' filterKey={me.state.params.dangerSubTypeId} textProperty='dangerName'/>
                    </Row>
                    <Row>
                        <InputItem title='TAG号' property='tag'/>
                        <InputItem type='date' title='排查开始日期' isSearch property='checkDateFrom'/>
                        <InputItem type='date' title='排查结束日期' isSearch property='checkDateTo'/>
                        <InputItem type='bool' title='整改完成' property='completed'/>
                    </Row>
                    <Row style={{flex: 4}}>
                        <InputItem type='bool' title='超时' property='overdue'/>
                        <span style={{flex: 2}}/>
                        <div className='div-container'>
                            <Button style={{ margin: 'auto 10px' }} onClick={e=>{
                                me.searchForm.validateFields().then(values => {
                                    const conditions = TableUtils.getSearchConditions(values, {
                                        dangerLevel: 'eq',
                                        actionType: 'eq',
                                        dangerSourceId: 'eq',
                                        districtId: 'eq',
                                        storeyId: 'eq',
                                        dangerTypeId: 'eq',
                                        dangerSubTypeId: 'eq',
                                        dangerId: 'eq',
                                        completed: 'eq',
                                        endTime: 'le',
                                        checkDateFrom: 'false',
                                        checkDateTo: 'false'
                                    });
                                    if (!SysUtils.isEmpty(values.checkDateFrom)) {
                                        const condition = {name: 'checkDate', value: values.checkDateFrom, relation: 'ge'};
                                        conditions.push(condition);
                                    }
                                    if (!SysUtils.isEmpty(values.checkDateTo)) {
                                        const condition = {name: 'checkDate', value: values.checkDateTo, relation: 'le'};
                                        conditions.push(condition);
                                    }
                                    me.state.conditions = conditions;
                                    controller.search();
                                });
                            }}>查询</Button>
                        </div>
                    </Row>
                </Form>
                <DangerCheckEdit
                    controller={me.state.controller}
                    visible={me.state.editVisible}
                    data={me.state.current}
                    onOk={me.state.controller.handleOk}
                    onCancel={me.state.controller.handleCancel}
                />
                {
                    this.state.imageVisible && <ImageView visible={this.state.imageVisible} imagePath={this.state.imagePath} onCancel={()=>{
                        this.setState({imageVisible: false});
                    }}/>
                }
                <Table {...renderProps} />
            </Resizable>
        );
    }
}

export default DangerCheckList;
