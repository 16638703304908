import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class UserStore extends BaseStore {
    method = 'user';
}

const userStore = new UserStore();
export default userStore;
