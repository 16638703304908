import React from 'react';
import Toast from './toast';
import SysUtils from './sysutils';
import RequestUtils from './requestutils';

class BaseTreeController {

    formRef = React.createRef();

    menu = null;
    method = '';
    parentProperty = '';
    parentController = null;
    view = null;
    children = null;
    filter = null;
    afterAdd = null;

    constructor(view, method, parentProperty = '') {
        this.menu = view.props.menu;
        this.view = view;
        this.method = method;
        this.parentProperty = parentProperty;
        this.children = [];
    }

    getOkText = () =>{
        const menu = this.getMenu();
        return SysUtils.isEmpty(menu) || menu.canEdit ? '保存' : '关闭';
    }

    checkReadOnly = () => {
        const menu = this.getMenu();
        return !SysUtils.isEmpty(menu) && !menu.canEdit;
    }

    getMenu = () => {
        if (!SysUtils.isEmpty(this.menu)) {
            return this.menu;
        } else if (!SysUtils.isEmpty(this.parentController)) {
            return this.parentController.getMenu();
        }
        return {
            canAll: false,
            canEdit: false,
            canDelete: false,
            canPrint: false
        };
    }

    getData = ()=>{
        return this.view.state.data;
    }

    setData = (result)=>{
        this.view.setState({
            data: {
                result: result.result,
                message: result.message,
                datas: result.rows
            },
            currentPage: result.currentPage,
            pageSize: result.pageSize,
            totalCount: result.totalCount,
        });
    }

    isEmpty = ()=>{
        return this.getData() === null;
    }

    load = (force, showLoadInfo = true) =>{
        if (force || this.isEmpty()) {
            RequestUtils.search(this.method, null, result=>{
                if (showLoadInfo) {
                    Toast.closeAll();
                    Toast.loadInfo(result);
                }
                if (result.result) {
                    this.setData(result);
                }
            });
        }
    }

    formatValue = (data) => {
        if (!SysUtils.isEmpty(data)) {
            for (let property in data) {
                if (property.endsWith('Id') && (property !== 'id')) {
                    if (data[property] === 0) {
                        data[property] = undefined;
                    }
                }
            }
        }
        return data;
    }

    showModal = (data) => {
        const form = this.form;
        const values = this.formatValue(data);
        form.setFieldsValue(values);
        this.view.setState({ editVisible: true, current: values });
    }

    closeModel = () =>{
        const form = this.form;
        form.resetFields();
        this.view.setState({ editVisible: false });
    }

    handleCancel = () => {
        const form = this.form;
        form.resetFields();
        this.view.setState({ editVisible: false });
    };

    handleOk = () => {
        const form = this.form;
        form.validateFields().then(values => {
            const data = this.view.state.current;
            for (let property in values) {
                data[property] = values[property];
            }
            RequestUtils.save(this.method, data, result=>{
                Toast.saveInfo(result);
                form.resetFields();
                this.closeModel();
                this.load(true, false);
            });
        });
    };
    add = ()=>{
        RequestUtils.create(this.method, null, result=>{
            Toast.createInfo(result);
            if (result.result) {
                this.showModal(result.data);
            }
        });
    }
    addChild = (parent)=>{
        RequestUtils.create(this.method, null, result=>{
            result.data.superId = parent.id;
            Toast.createInfo(result);
            if (result.result) {
                this.showModal(result.data);
            }
        });
    }
    edit = (data)=>{
        this.showModal(data);
    }
    delete = (data)=>{
        Toast.deleteConfirm(()=>{
            const params = {
                id: data.id
            };
            RequestUtils.delete(this.method, params, result=>{
                Toast.deleteInfo(result);
                this.load(true, false);
            });
        });
    }
    refresh = ()=>{
        Toast.refreshConfirm(()=>{
            this.load(true);
        });
    }
    getById = id => {
        if (!this.isEmpty()) {
            this.getData().data.forEach(item => {
                if (id === item.id) {
                    return item;
                }
            });
        }
        return null;
    }
}

export default BaseTreeController;
