import React from 'react';
import { Menu } from 'antd';
import {
    HomeOutlined,
    UserOutlined,
    LockOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    SyncOutlined,
    PlusCircleOutlined,
    CheckOutlined,
    ReloadOutlined,
    KeyOutlined,
    PoweroffOutlined,
    DownOutlined,
    EllipsisOutlined,
} from '@ant-design/icons';

class ToolBar extends React.Component {

    handleClick = e => {
        const { view } = this.props;
        const key = e.key;
        if (SysUtils.isEmpty(view[key])) {
            const controller = view.state.controller;
            controller && controller[key] && controller[key]();
        } else {
            view[e.key] && view[e.key]();
        }
    };
    render() {
        return (
            <Menu onClick={this.handleClick} mode='horizontal' style={{lineHeight: '35px'}}>
                <Menu.Item key='add'>
                    <PlusCircleOutlined />
                    新增
                </Menu.Item>
                <Menu.Item key='edit'>
                    <EditOutlined />
                    修改
                </Menu.Item>
                <Menu.Item key='delete'>
                    <DeleteOutlined />
                    删除
                </Menu.Item>
                <Menu.Item key='refresh'>
                    <ReloadOutlined />
                    刷新
                </Menu.Item>
            </Menu>
        );
    }
}

export default ToolBar;
