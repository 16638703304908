import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class ExaminePathStore extends BaseStore {
    method = 'examinePath';
}

const examinePathStore = new ExaminePathStore();
export default examinePathStore;
