import RequestUtils from './../components/requestutils';
import SysUtils from './../components/sysutils';
import Toast from './../components/toast';

export default class BaseStore {

    dicts = null;
    pickerDatas = null;
    items = null;
    item = null;
    forceDict = false;
    parentProperty = '';
    method = '';
    needSaveToItem = false

    getDefaultResult() {
        return {
            result: true,
            data: this.item,
            rows: this.items
        };
    }

    getItemById(id) {
        if (this.items !== null) {
            for (var item of this.items) {
                if (item.id === id) {
                    return item;
                }
            }
        }
        return null;
    }

    getDictById(id) {
        if (this.dicts !== null) {
            for (var item of this.dicts) {
                if (item.id === id) {
                    return item;
                }
            }
        }
        return null;
    }

    refreshItems(item, first) {
        if (SysUtils.isEmpty(this.items)) {
            this.items = [];
        }
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].id === item.id) {
                this.items[i] = item;
                return;
            }
        }
        if (first) {
            this.items.unshift(item);
        } else {
            this.items.push(item);
        }
    }

    editOrCreateItem(id, callBack) {
        if (SysUtils.isZero(id)) {
            this.createItem(callBack);
        } else {
            this.editItem(id, callBack);
        }
    }

    clear() {
        this.dicts = null;
        this.pickerDatas = null;
        this.items = null;
        this.item = null;
        this.clearDetails();
    }

    clearDetails() {

    }

    editItem(id, callBack) {
        const params = {id: id};
        if (this.item === null || this.item === undefined) {
            RequestUtils.edit(this.method, params, (result) => {
                this.item = result.data;
                if (!SysUtils.isEmpty(this.item)) {
                    this.item.needSaveToItem = this.needSaveToItem;
                }
                callBack && callBack(result);
            });
        } else {
            callBack && callBack(this.getDefaultResult());
        }
    }

    createItem(callBack) {
        if (this.item === null || this.item === undefined) {
            RequestUtils.create(this.method, null, (result) => {
                this.item = result.data;
                if (!SysUtils.isEmpty(this.item)) {
                    this.item.needSaveToItem = this.needSaveToItem;
                }
                callBack && callBack(result);
            });
        }
    }

    getItems() {
        return this.items;
    }

    list(callBack) {
        if (this.items === null || this.items === undefined) {
            RequestUtils.list(this.method, null, (result) => {
                this.items = result.rows;
                this.items.forEach(element => {
                    element.needSaveToItem = this.needSaveToItem;
                });
                callBack && callBack(result);
            });
        } else {
            callBack && callBack(this.getDefaultResult());
        }
    }
    dictList(callBack) {
        if (this.items === null || this.items === undefined) {
            RequestUtils.dictList(this.method, null, (result) => {
                this.items = result.rows;
                this.count = this.items.length;
                this.items.forEach(element => {
                    element.needSaveToItem = this.needSaveToItem;
                });
                callBack && callBack(result);
            });
        } else {
            callBack && callBack(this.getDefaultResult());
        }
    }
    listByParentId(parentId, callBack) {
        if (SysUtils.isZero(parentId)) {
            if (this.items === null || this.items === undefined) {
                this.items = [];
            }
            callBack && callBack({});
        } else if (this.items === null || this.items === undefined) {
            let params = {};
            if (!SysUtils.isEmpty(this.parentProperty)) {
                params.parentProperty = this.parentProperty;
                params.parentId = parentId;
            }
            RequestUtils.list(this.method, params, (result) => {
                this.items = result.rows;
                this.items.forEach(element => {
                    element.needSaveToItem = this.needSaveToItem;
                });
                callBack && callBack(result);
            });
        } else {
            callBack && callBack(this.getDefaultResult());
        }
    }
    dictListByParentId(parentId, callBack) {
        if (SysUtils.isZero(parentId)) {
            if (this.items === null || this.items === undefined) {
                this.items = [];
            }
            callBack && callBack({});
        } else if (this.items === null || this.items === undefined) {
            let params = {};
            if (!SysUtils.isEmpty(this.parentProperty)) {
                params.parentProperty = this.parentProperty;
                params.parentId = parentId;
            }
            RequestUtils.dictList(this.method, params, (result) => {
                this.items = result.rows;
                this.items.forEach(element => {
                    element.needSaveToItem = this.needSaveToItem;
                });
                callBack && callBack(result);
            });
        } else {
            callBack && callBack(this.getDefaultResult());
        }
    }

    findItem(method, params, callBack) {
        if (this.item === null || this.item === undefined) {
            RequestUtils.execute(method, params, (result) => {
                this.item = result.data;
                this.item.needSaveToItem = this.needSaveToItem;
                callBack && callBack(result);
            });
        } else {
            callBack && callBack(this.getDefaultResult());
        }
    }

    findItems(method, params, callBack) {
        if (this.items === null || this.items === undefined) {
            RequestUtils.execute(method, params, (result) => {
                this.items = result.rows;
                this.items.forEach(element => {
                    element.needSaveToItem = this.needSaveToItem;
                });
                callBack && callBack(result);
            });
        } else {
            callBack && callBack(this.getDefaultResult());
        }
    }

    getDicts() {
        return this.dicts;
    }

    dict(callBack) {
        if (this.forceDict || this.dicts === null || this.dicts === undefined) {
            RequestUtils.dict(this.method, null, (result) => {
                this.dicts = result.rows;
                callBack && callBack(result);
            });
        } else {
            callBack && callBack(this.getDefaultResult());
        }
    }
    save(data, refreshItems, callBack) {
        Toast.dataSaving();
        RequestUtils.save(this.method, data, (result) => {
            Toast.closeAll();
            Toast.saveInfo(result);
            if (result.result) {
                callBack && callBack(result);
            }
        });
    }
    delete(data, callBack) {
        Toast.dataDeleting();
        const params = {
            id: data.id
        };
        RequestUtils.delete(this.method, params, (result) => {
            Toast.closeAll();
            Toast.deleteInfo(result);
            if (result.result) {
                callBack && callBack(result);
            }
        });
    }
}
