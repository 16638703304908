import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class ActionTypeStore extends BaseStore {
    method = 'enum/actionType';
}

const actionTypeStore = new ActionTypeStore();
export default actionTypeStore;
