import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class CompanyGroupStore extends BaseStore {
    method = 'companyGroup';
}

const companyGroupStore = new CompanyGroupStore();
export default companyGroupStore;
