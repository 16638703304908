import React from 'react';
import Toast from './toast';
import SysUtils from './sysutils';
import moment from 'moment';
import RequestUtils from './requestutils';

class BaseController {

    menu = null;
    method = '';
    parentProperty = '';
    parentController = null;
    view = null;
    children = null;
    filter = null;
    afterAdd = null;

    constructor(view, method, parentProperty = '') {
        this.menu = view.props.menu;
        this.view = view;
        this.method = method;
        this.parentProperty = parentProperty;
        this.children = [];
    }

    getOkText = () =>{
        const menu = this.getMenu();
        return menu === null || menu.canEdit ? '保存' : '关闭';
    }

    checkReadOnly = () => {
        const menu = this.getMenu();
        return !SysUtils.isEmpty(menu) && !menu.canEdit;
    }

    openSearch = () => {
        this.view.setState({
            showSearch: true
        });
    }

    getData = () => {
        return this.view.state.data;
    }

    getCurrent = () => {
        return this.view.state.current;
    }

    setCurrent = data => {
        this.view.state.current = null;
    }

    clearCurrent = () => {
        this.setCurrent(null);
    }

    getParentId = () => {
        return this.view.getParentId();
    }

    setCurrentPage = value => {
        this.view.state.currentPage = value;
    }

    setData = (result) => {
        const current = result.rows.length > 0 ? result.rows[0] : null;
        this.view.setState({
            data: {
                result: result.result,
                message: result.message,
                datas: result.rows
            },
            selectedRowId: current === null ? 0 : current.id,
            current: current,
            currentPage: result.currentPage,
            pageSize: result.pageSize,
            totalCount: result.totalCount,
        });
        this.loadChildren();
    }

    getEmptyData = () => {
        return {
            result: false,
            message: '',
            rows: [],
            currentPage: 0,
            pageSize: 20,
            totalCount: 0
        };
    }

    isEmpty = () => {
        return this.getData() === null;
    }

    addChild = (child) => {
        this.children.push(child);
        child.parentController = this;
    }

    loadChildren = () => {
        for (let i = 0; i < this.children.length; i++) {
            this.children[i].loadChild();
        }
    }

    loadChild = () => {
        if (SysUtils.isZero(this.getParentId())) {
            this.clearCurrent();
            this.setData(this.getEmptyData());
        } else {
            const params = {
                parentProperty: this.parentProperty,
                parentId: this.getParentId()
            };
            RequestUtils.list(this.method, params, result => {
                if (result.result) {
                    this.setData(result);
                    this.loadChildren();
                }
            });
        }
    }

    search = () => {
        Toast.dataLoading(hider=>{
            RequestUtils.loadingHider = hider;
            this.load(true);
        });
    }

    canOperate = (operateType) =>{
        if (SysUtils.isEmpty(operateType)) {
            return true;
        }
        const menu = this.getMenu();
        if (!SysUtils.isEmpty(menu)) {
            if (operateType === 'add') {
                return menu.canAdd;
            } else if (operateType === 'edit') {
                return menu.canEdit;
            }
        }
        return true;
    }

    getMenu = () => {
        if (!SysUtils.isEmpty(this.menu)) {
            return this.menu;
        } else if (!SysUtils.isEmpty(this.parentController)) {
            return this.parentController.getMenu();
        }
        return {
            canAll: false,
            canEdit: false,
            canDelete: false,
            canPrint: false
        };
    }

    load = (force, showLoadInfo = true) => {
        this.view.setState({
            editing: false,
            edited: false
        });
        if (force || this.isEmpty()) {
            if (SysUtils.isEmpty(this.parentProperty)) {
                const params = {
                    page: this.view.state.currentPage,
                    pageSize: this.view.state.pageSize,
                    filter: this.filter,
                    conditions: this.view.state.conditions
                };
                RequestUtils.search(this.method, params, result => {
                    if (showLoadInfo) {
                        Toast.closeAll();
                        Toast.loadInfo(result);
                    }
                    if (result.result) {
                        this.setData(result);
                        this.loadChildren();
                    }
                });
            } else {
                if (SysUtils.isZero(this.getParentId())) {
                    this.clearCurrent();
                    this.setData(this.getEmptyData());
                } else {
                    const params = {
                        parentProperty: this.parentProperty,
                        parentId: this.getParentId(),
                        filter: this.filter,
                        conditions: this.view.state.conditions
                    };
                    RequestUtils.list(this.method, params, result => {
                        if (showLoadInfo) {
                            Toast.closeAll();
                            Toast.loadInfo(result);
                        }
                        if (result.result) {
                            this.setData(result);
                            this.loadChildren();
                        }
                    });
                }
            }
        }
    }

    showModal = (data) => {
        const form = this.form;
        for (let property in data) {
            if (data[property] === null || property === 'createTime' || property === 'updateTime') {
                continue;
            }
            if (property.toLowerCase().endsWith('date') || property.toLowerCase().endsWith('time')) {
                if (typeof data[property] !== 'number') {
                    data[property] = moment(data[property]);
                }
            }
        }
        form.setFieldsValue(data);
        this.view.setState({ editVisible: true, current: data });
    }

    closeModel = () => {
        const form = this.form;
        form.resetFields();
        this.view.setState({ editVisible: false });
    }

    handleCancel = () => {
        const form = this.form;
        form.resetFields();
        this.view.setState({
            current: this.oldCurrent,
            editVisible: false
        });
    };

    handleOk = () => {
        const form = this.form;
        form.validateFields().then(values => {
            const data = this.view.state.current;
            for (let property in values) {
                const value = values[property];
                if (value instanceof Array) {
                    let result = '';
                    for (let i = 0; i < value.length; i++) {
                        if (!SysUtils.isEmpty(result)) {
                            result += ',';
                        }
                        if (SysUtils.isEmpty(value[i].value)) {
                            result += value[i];
                        } else {
                            result += value[i].value;
                        }
                    }
                    data[property] = result;
                } else {
                    data[property] = value;
                }
            }
            if (!SysUtils.isEmpty(this.parentProperty)) {
                data.parentProperty = this.parentProperty;
                data.parentId = this.getParentId();
            }
            RequestUtils.save(this.method, data, result => {
                Toast.saveInfo(result);
                if (result.result) {
                    form.resetFields();
                    this.closeModel();
                    this.load(true, false);
                }
            });
        });
    }
    add = (source) => {
        this.oldCurrent = this.getCurrent();
        if (SysUtils.isEmpty(this.parentProperty)) {
            RequestUtils.create(this.method, null, result => {
                Toast.createInfo(result);
                if (result.result) {
                    this.showModal(result.data);
                }
            });
        } else {
            if (SysUtils.isZero(this.getParentId())) {
                Toast.error('选择主表数据后才能新增');
            } else {
                const params = {
                    parentProperty: this.parentProperty,
                    parentId: this.getParentId()
                };
                RequestUtils.create(this.method, params, result => {
                    Toast.createInfo(result);
                    if (result.result) {
                        this.afterAdd && this.afterAdd(result.data, source);
                        result.data[this.parentProperty] = this.getParentId();
                        this.showModal(result.data);
                    }
                });
            }
        }
    }
    edit = (data) => {
        this.oldCurrent = this.getCurrent();
        RequestUtils.edit(this.method, { id: data.id }, result => {
            this.showModal(result.data);
        });
    }
    editBatch = () => {
        if (this.parentController !== null) {
            this.parentController.view.state.editing = true;
        }
        this.view.setState({
            editing: true,
        });
    }
    cancelBatch = () => {
        if (!this.view.state.edited) {
            if (this.parentController !== null) {
                this.parentController.view.state.editing = false;
            }
            this.view.setState({
                editing: false
            });
            return;
        }
        Toast.confirm('是否取消当前编辑', () => {
            const params = {
                parentProperty: this.parentProperty,
                parentId: this.getParentId(),
                filter: this.filter,
                conditions: this.view.state.conditions
            };
            RequestUtils.list(this.method, params, result => {
                Toast.closeAll();
                result.result ? Toast.success('批量编辑取消成功') : Toast.error('批量编辑取消失败');
                if (result.result) {
                    this.setData(result);
                    if (this.parentController !== null) {
                        this.parentController.view.state.editing = false;
                    }
                    this.view.setState({
                        editing: false,
                        edited: false
                    });
                }
            });
        });
    }
    saveBatch = () => {
        if (!this.view.state.edited) {
            this.view.setState({
                editing: false
            });
            return;
        }
        Toast.confirm('是否保存当前编辑', () => {
            const params = {
                parentProperty: this.parentProperty,
                parentId: this.getParentId(),
                datas: this.getData().datas
            };
            RequestUtils.batchSave(this.method, params, result => {
                if (result.result) {
                    const params = {
                        parentProperty: this.parentProperty,
                        parentId: this.getParentId(),
                        filter: this.filter,
                        conditions: this.view.state.conditions
                    };
                    RequestUtils.list(this.method, params, result => {
                        Toast.closeAll();
                        result.result ? Toast.success('批量保存成功') : Toast.error('批量保存失败');
                        if (result.result) {
                            this.setData(result);
                            this.view.setState({
                                editing: false,
                                edited: false
                            });
                        }
                    });
                } else {
                    Toast.error('保存失败');
                }
            });
        });
    }
    allowDelete = (callback, data) => {
        const params = {
            key: data.id
        };
        RequestUtils.execute(this.method + '/alllowDelete', params, result => {
            if (result.result) {
                callback(data);
            } else {
                Toast.error(result.message);
            }
        });
    }

    delete = (data) => {
        this.allowDelete((data) => {
            Toast.deleteConfirm(() => {
                const params = {
                    id: data.id
                };
                RequestUtils.delete(this.method, params, result => {
                    Toast.deleteInfo(result);
                    this.load(true, false);
                });
            });
        }, data);
    }
    refresh = () => {
        Toast.refreshConfirm(() => {
            Toast.dataLoading(hider=>{
                RequestUtils.loadingHider = hider;
                this.load(true);
            });
        });
    }
    getById = id => {
        if (!this.isEmpty()) {
            this.getData().data.forEach(item => {
                if (id === item.id) {
                    return item;
                }
            });
        }
        return null;
    }
}

export default BaseController;
