import React from 'react';
import { Button, Modal } from 'antd';
import RequestUtils from './requestutils';

const ImageView = (props) => {

    const { visible, imagePath, onCancel } = props;

    return (
        <Modal
            visible={visible}
            title='图片'
            cancelText='取消'
            maskClosable={true}
            onCancel={onCancel}
            centered
            footer={<div
                    style={{
                        width: '100%',
                        background: '#fff',
                        textAlign: 'right',
                    }}>
                        <Button type='primary' onClick={e=>{
                            onCancel && onCancel();
                        }}>关闭</Button>
                    </div>
            }
        >
            <div style={{display: 'flex', width: '100%', height: '100%'}}>
                <img style={{margin: 'auto', width: 'auto', height: 'auto', maxHeight: '100%', maxWidth: '100%'}} src={RequestUtils.getImageUrl(imagePath)}/>
            </div>
        </Modal>
    );
};

export default ImageView;
