import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class DistrictStore extends BaseStore {
    method = 'district';
}

const districtStore = new DistrictStore();
export default districtStore;
