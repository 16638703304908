import React from 'react';
import { Table } from 'antd';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.metro.css';
import JqxSplitter from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxsplitter';
import Resizable from 'react-component-resizable';
import './../../../style.less';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import BaseController from '../../components/basecontroller';
import CompanyGroupEdit from './companygroupedit';
import CompanyGroupDetailList from './companygroupdetaillist';

class CompanyGroupList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editVisible: false,
            controller: new BaseController(this, 'companyGroup'),
            selectedRowId: 0,
            tabIndex: 0,
            data: null,
            contentHeight: 100,
            contentWidth: 600,
            leftWidth: null,
            currentPage: 1,
            pageSize: 20,
            current: null
        };
        this.columns = [
            { dataIndex: 'name', title: '名称', align: 'center', width: 200 },
            { dataIndex: 'allCompany', title: '全部公司', align: 'center', width: 80, render: TableUtils.boolFormat },
            { dataIndex: 'sort', title: '顺序', align: 'center', width: 65 },
            { dataIndex: 'usable', title: '可用', align: 'center', width: 65, render: TableUtils.boolFormat },
            {},
            TableUtils.getAction(this.state.controller)
        ];
    }

    componentDidMount() {
        this.state.controller.load();
    }

    loadChildren = (newId, id) => {
        if (this.state.data !== null) {
            const current = TableUtils.getRowById(this.state.data.datas, newId);
            if (!SysUtils.isEmpty(current)) {
                this.state.current = current;
                this.state.controller.loadChildren();
            }
        }
    }

    getMainWidth = ()=> {
        return SysUtils.isEmpty(this.state.leftWidth) ? (this.state.contentWidth - 500) : (this.state.contentWidth - this.state.leftWidth);
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading/>;
        }
        const me = this;
        const data = me.state.data;
        const rowSelection = {
            type: 'radio',
            columnWidth: '30px',
            fixed: true,
            selectedRowKeys: [this.state.selectedRowId],
            onChange: selectedRowKeys => {
                if (selectedRowKeys.length > 0) {
                    this.loadChildren(selectedRowKeys[0], this.state.selectedRowId);
                }
                me.setState({
                    selectedRowId: selectedRowKeys.length > 0 ? selectedRowKeys[0] : 0
                });
            }
        };
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableUsedHeight(me.state) },
            pagination: TableUtils.getPagination(this.state.controller, this.state),
            columns: this.columns,
            size: 'small',
            rowKey: 'id',
            rowSelection,
            dataSource: data.datas,
            title: () => {
                return TableUtils.getActionBar(this.state.controller);
            },
            onRow: (record, index) => ({
                onClick: () => {
                    const oldId = me.state.selectedRowId;
                    this.setState({
                        selectedRowId: record.id
                    });
                    this.loadChildren(record.id, oldId);
                },
            })
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                this.setState({
                    contentHeight: e.height,
                    contentWidth: e.width
                });
                if (SysUtils.isEmpty(this.state.leftWidth)) {
                    this.setState({
                        leftWidth: 500
                    });
                }
            }}>
                <JqxSplitter
                    width={ me.state.contentWidth }
                    height={ me.state.contentHeight }
                    panels={[{ size: me.getMainWidth(), min: 100, collapsible: false }, { size: SysUtils.isEmpty(me.state.leftWidth) ? 500 : me.state.leftWidth, min: 100, collapsible: false }]}
                >
                    <Resizable className={'splitter-panel'} style={{height: SysUtils.getContentHeight()}} onResize={e=>{
                        if (!SysUtils.isEmpty(me.state.leftWidth)) {
                            me.setState({
                                leftWidth: me.state.contentWidth - e.width
                            });
                        }
                    }}>
                        <CompanyGroupEdit
                            controller={me.state.controller}
                            visible={me.state.editVisible}
                            data={me.state.current}
                            onOk={me.state.controller.handleOk}
                            onCancel={me.state.controller.handleCancel}
                        />
                        <Table {...renderProps}/>
                    </Resizable>
                    <div className='splitter-panel'>
                        <CompanyGroupDetailList parentController={me.state.controller}/>
                    </div>
                </JqxSplitter>
            </Resizable>
        );
    }
}

export default CompanyGroupList;
