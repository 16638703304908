import React from 'react';
import {
    HomeOutlined,
    UserOutlined,
    LockOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    SyncOutlined,
    PlusCircleOutlined,
    CheckOutlined,
    ReloadOutlined,
    KeyOutlined,
    PoweroffOutlined,
    DownOutlined,
    EllipsisOutlined,
    UploadOutlined,
    DownloadOutlined,
    AppstoreOutlined,
    SettingOutlined,
    FileSyncOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';

const Icon = (props) => {
    switch (props.type) {
        case 'user': return <UserOutlined {...props}/>;
        case 'lock': return <LockOutlined {...props}/>;
        case 'edit': return <EditOutlined {...props}/>;
        case 'delete': return <DeleteOutlined {...props}/>;
        case 'plus': return <PlusOutlined {...props}/>;
        case 'sync': return <SyncOutlined {...props}/>;
        case 'plus-circle': return <PlusCircleOutlined {...props}/>;
        case 'check': return <CheckOutlined {...props}/>;
        case 'reload': return <ReloadOutlined {...props}/>;
        case 'key': return <KeyOutlined {...props}/>;
        case 'poweroff': return <PoweroffOutlined {...props}/>;
        case 'down': return <DownOutlined {...props}/>;
        case 'ellipsis': return <EllipsisOutlined {...props}/>;
        case 'upload': return <UploadOutlined {...props}/>;
        case 'download': return <DownloadOutlined {...props}/>;
        case 'appstore': return <AppstoreOutlined {...props}/>;
        case 'setting': return <SettingOutlined {...props}/>;
        case 'file-sync': return <FileSyncOutlined {...props}/>;
        case 'clock_circle': return <ClockCircleOutlined {...props}/>;
    }
    return <HomeOutlined {...props}/>;
};

export default Icon;