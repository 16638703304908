import React from 'react';
import './../../../style.less';
import { Table } from 'antd';
import Resizable from 'react-component-resizable';
import ToolBar from '../../components/toolbar';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import BaseController from '../../components/basecontroller';
import DangerTypeEdit from './dangertypeedit';

class DangerTypeList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editVisible: false,
            controller: new BaseController(this, 'dangerType'),
            data: null,
            contentHeight: 100,
            currentPage: 1,
            pageSize: 20,
            current: null
        };
        this.columns = [
            { dataIndex: 'code', title: '编号', align: 'center', width: 200 },
            { dataIndex: 'name', title: '名称', align: 'center', width: 200 },
            { dataIndex: 'sort', title: '顺序', align: 'center', width: 65 },
            { dataIndex: 'usable', title: '可用', align: 'center', width: 65, render: TableUtils.boolFormat },
            {},
            TableUtils.getAction(this.state.controller)
        ];
    }

    componentDidMount() {
        this.state.controller.load();
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;
        const data = this.state.data;
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableUsedHeight(me.state) },
            pagination: TableUtils.getPagination(me.state.controller, me.state),
            columns: me.columns,
            size: 'small',
            dataSource: data.datas,
            title: () => {
                return TableUtils.getActionBar(me.state.controller);
            }
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                this.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                <DangerTypeEdit
                    controller={me.state.controller}
                    visible={me.state.editVisible}
                    data={me.state.current}
                    onOk={me.state.controller.handleOk}
                    onCancel={me.state.controller.handleCancel}
                />
                <Table {...renderProps} />
            </Resizable>
        );
    }
}

export default DangerTypeList;
