import React from 'react';
import SysUtils from './sysutils';
import RequestUtils from './requestutils';
import Toast from './toast';

class LogonUtils {

    user = null;

    logout = (callback) => {
        RequestUtils.logout(result=>{
            this.clearLogonInfo();
            callback && callback(result);
        });
    }

    clearLogonInfo = () => {
        this.user = null;
        localStorage.setItem('token', '');
    }

    logoned = () => {
        return !SysUtils.isEmpty(this.user);
    }

    logon = (params, callback) => {
        RequestUtils.execute('logon/logon', params, result=>{
            if (result.result) {
                localStorage.setItem('token', result.data.token);
                localStorage.setItem('name', result.data.name);
                localStorage.setItem('companyId', result.data.companyId);
                localStorage.setItem('companyName', result.data.companyName);
                this.user = result.data;
            } else {
                Toast.error(result.message);
            }
            callback && callback(result);
        });
    }

    checkLogon = (callback) => {
        RequestUtils.execute('logon/checkLogon', null, result=>{
            if (result.result) {
                if (result.errorType !== 'DisLogon') {
                    localStorage.setItem('token', result.data.token);
                    localStorage.setItem('name', result.data.name);
                    localStorage.setItem('companyId', result.data.companyId);
                    localStorage.setItem('companyName', result.data.companyName);
                    this.user = result.data;
                }
            }
            callback && callback(result);
        });
    }
}

const logonUtils = new LogonUtils();
export default logonUtils;
