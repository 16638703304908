import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class DangerTypeStore extends BaseStore {
    method = 'dangerType';
}

const dangerTypeStore = new DangerTypeStore();
export default dangerTypeStore;
