import React from 'react';
import { Empty } from 'antd';

class DataLoading extends React.Component {
    render() {
        return <div style={{width: 'vw100', height: 'vh100'}} className='div-container'>
            <div style={{margin: 'auto'}}>
                <Empty description='数据加载中...'/>
            </div>
        </div>;
    }
}

export default DataLoading;
