import React from 'react';
import { Modal, Form } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from './../../components/selectitem';
import TableUtils from './../../components/tableutils';

const DepartmentExamineDirectionUserEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;

    return (
        <Modal
            visible={visible}
            title='部门检查方向参与人'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <SelectItem title='检查方向' data={data} method='examineDirection' property='examineDirectionId' textProperty='examineDirectionName'/>
                <SelectItem title='部门' data={data} method='department' property='departmentId' textProperty='departmentName'/>
                <SelectItem title='参与人' data={data} method='user' property='userId' textProperty='userName'/>
                <InputItem type='number' title='顺序' property='sort'/>
                <InputItem type='checkbox' title='可用' property='usable'/>
            </Form>
        </Modal>
    );
};

export default DepartmentExamineDirectionUserEdit;
