import React from 'react';
import './../../../style.less';
import { Table } from 'antd';
import Resizable from 'react-component-resizable';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import BaseController from '../../components/basecontroller';
import PatrolPlanDetailEdit from './patrolplandetailedit';

class PatrolPlanDetailList extends React.Component {

    constructor(props) {
        super(props);
        const { parentController } = this.props;
        this.state = {
            editVisible: false,
            parentController: null,
            controller: new BaseController(this, 'patrolPlanDetail', 'patrolPlanId'),
            data: null,
            contentHeight: 100,
            current: null
        };
        parentController.children.push(this.state.controller);
        this.columns = [
            { dataIndex: 'equipmentName', title: '巡检装置', align: 'center', width: 80 },
            { dataIndex: 'partName', title: '巡检区域', align: 'center', width: 80 },
            { dataIndex: 'sort', title: '顺序', align: 'center', width: 65 },
            {},
            TableUtils.getAction(this.state.controller)
        ];
    }

    getParentId = ()=>{
        const { parentController } = this.props;
        if (parentController !== null && parentController.getCurrent()) {
            return parentController.getCurrent().id;
        }
        return 0;
    }

    componentDidMount() {
        const { parentController } = this.props;
        if (this.getParentId() === 0) {
            this.state.controller.setData(this.state.controller.getEmptyData());
        } else {
            this.state.controller.loadChild();
        }
    }

    componentWillUnmount() {
        const { parentController } = this.props;
        parentController.children.remove(this.state.controller);
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;
        const data = this.state.data;
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getDetailTableUsedHeight() },
            pagination: false,
            columns: this.columns,
            size: 'small',
            dataSource: data.datas,
            title: () => {
                return TableUtils.getDetailActionBar(this.state.controller, '计划明细');
            }
        };
        return (
            <Resizable style={{height: SysUtils.getContentHeight()}} onResize={e=>{
                this.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                <PatrolPlanDetailEdit
                    controller={me.state.controller}
                    visible={me.state.editVisible}
                    data={me.state.current}
                    onOk={me.state.controller.handleOk}
                    onCancel={me.state.controller.handleCancel}
                />
                <Table {...renderProps}/>
            </Resizable>
        );
    }
}

export default PatrolPlanDetailList;
