import React from 'react';
import './../../../style.less';
import { Table, Form, Row, Button, Divider, Tooltip, Upload } from 'antd';
import {
    PlusOutlined,
    SyncOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import Resizable from 'react-component-resizable';
import Lightbox from 'react-image-lightbox';
import ToolBar from '../../components/toolbar';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import RequestUtils from '../../components/requestutils';
import BaseController from '../../components/basecontroller';
import Icon from './../../components/icon';
import DangerActionDelayApplyEdit from './dangeractiondelayapplyedit';
import Toast from '../../components/toast';
import ImageView from './../../components/imageview';
import InputItem from '../../components/inputitem';
import SelectItem from '../../components/selectitem';

class DangerActionDelayApplyList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editVisible: false,
            imageVisible: false,
            imagePath: '',
            controller: new BaseController(this, 'DangerActionDelayApply'),
            data: null,
            contentHeight: 100,
            currentPage: 1,
            pageSize: 20,
            current: null,
            params: {
                districtId: 0,
                dangerTypeId: 0,
                dangerSubTypeId: 0
            }
        };
        const fontSize = '14px';
        this.columns = [
            { dataIndex: 'dangerCheckImagePath', title: '隐患照片', align: 'center', width: 80, render: (cellData, rowData)=>{
                return SysUtils.isEmpty(cellData) ? <span>无图片</span> : <span className='blue pointer' onClick={e=>{
                    this.setState({
                        imageVisible: true,
                        imagePath: cellData
                    });
                }}>查看图片</span>;
            } },
            { dataIndex: 'checkDate', title: '排查日期', align: 'center', width: 150, render: TableUtils.dateTimeFormat },
            { dataIndex: 'districtName', title: '区域装置', align: 'center', width: 80 },
            { dataIndex: 'storeyName', title: '层数', align: 'center', width: 80 },
            { dataIndex: 'tag', title: 'TAG号', align: 'center', width: 80 },
            { dataIndex: 'dangerLevelName', title: '隐患级别', align: 'center', width: 80 },
            { dataIndex: 'actionTypeName', title: '整改类型', align: 'center', width: 80 },
            { dataIndex: 'oldActionLimitDate', title: '原整改期限', align: 'center', width: 90, render: TableUtils.dateFormat },
            { dataIndex: 'actionLimitDate', title: '整改期限', align: 'center', width: 90, render: TableUtils.dateFormat },
            { dataIndex: 'applyUserName', title: '申请人', align: 'center', width: 80 },
            { dataIndex: 'delayReason', title: '延迟原因', align: 'center', width: 100 },
            { dataIndex: 'feedbackUserName', title: '审核人', align: 'center', width: 80 },
            { dataIndex: 'feedbackTypeName', title: '审核结果', align: 'center', width: 80 },
            { dataIndex: 'feedbackRemark', title: '审核备注', align: 'center', width: 150 },
            { dataIndex: 'dangerName', title: '隐患标准', align: 'center', width: 150 },
            { dataIndex: 'dangerContent', title: '隐患内容', align: 'center', width: 150 },
            {},
            {
                title: '数据操作',
                align: 'center',
                width: 80,
                fixed: 'right',
                key: 'operation',
                render: (text, record) => (
                    <div style={{ width: 80 }}>
                        <Tooltip placement='top' title='审核'>
                            <span style={{ cursor: 'pointer' }} onClick={e => {
                                this.state.controller.edit(record);
                            }}>
                                <CheckCircleOutlined style={{ color: '#FF3D3D', fontSize: fontSize }} />
                            </span>
                        </Tooltip>
                    </div>
                )
            }
        ];
    }

    componentDidMount() {
        this.state.controller.load();
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;
        const controller = me.state.controller;
        const data = this.state.data;
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableUsedHeight(me.state) - 111},
            pagination: TableUtils.getPagination(me.state.controller, me.state),
            columns: me.columns,
            size: 'small',
            dataSource: data.datas,
            title: () => {
                return <div className='div-container'>
                    <span style={{flex: 1, margin: 'auto 0'}}>
                        <span className='action-bar-button' style={{ cursor: 'pointer' }} onClick={e => {
                            controller.refresh();
                        }}>
                            <SyncOutlined style={{ padding: ' 0 5px' }} />刷新
                        </span>
                        <Divider type='vertical' />
                    </span>
                </div>;
            },
            rowClassName: (record, index) => {
                if (record.actionTime === null && record.overdue) {
                    return 'x-grid3-row-alt-red';
                } else if (record.applyActionLimitDate !== null) {
                    return 'x-grid3-row-alt-yellow';
                }
            }
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                this.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                <Form ref={r => me.searchForm = r} className='ant-two-column-form' style={{marginRight: 50}}>
                    <Row style={{marginTop: 4}}>
                        <Row style={{flex: 3}}>
                            <SelectItem title='区域装置' method='district' property='districtId' textProperty='districtName' onChange={value=>{
                                me.state.params.districtId = value;
                                me.searchForm.setFieldsValue({
                                    storeyId: null,
                                    storeyName: ''
                                });
                                this.forceUpdate();
                            }}/>
                            <SelectItem title='层数' method='storey' property='storeyId' filterKey={me.state.params.districtId} textProperty='storeyName'/>
                            <InputItem title='TAG号' property='tag'/>
                        </Row>
                        <div className='div-container'>
                            <Button style={{ margin: 'auto 10px' }} onClick={e=>{
                                me.searchForm.validateFields().then(values => {
                                    const conditions = TableUtils.getSearchConditions(values, {
                                        districtId: 'eq',
                                        storeyId: 'eq',
                                        applyUserId: 'eq',
                                        feedbackUserId: 'eq',
                                        feedbackType: 'eq',
                                        checkDateFrom: 'false',
                                        checkDateTo: 'false'
                                    });
                                    if (!SysUtils.isEmpty(values.checkDateFrom)) {
                                        const condition = {name: 'checkDate', value: values.checkDateFrom, relation: 'ge'};
                                        conditions.push(condition);
                                    }
                                    if (!SysUtils.isEmpty(values.checkDateTo)) {
                                        const condition = {name: 'checkDate', value: values.checkDateTo, relation: 'le'};
                                        conditions.push(condition);
                                    }
                                    me.state.conditions = conditions;
                                    controller.search();
                                });
                            }}>查询</Button>
                        </div>
                    </Row>
                    <Row>
                        <Row style={{flex: 3}}>
                            <SelectItem title='申请人' method='user' property='applyUserId' textProperty='applyUserName'/>
                            <SelectItem title='审核人' method='user' property='feedbackUserId' textProperty='feedbackUserName'/>
                            <SelectItem title='审核结果' method='enum/feedbackType' property='feedbackType' textProperty='feedbackTypeName'/>
                        </Row>
                        <span style={{flex: 1}}/>
                    </Row>
                    <Row>
                        <Row style={{flex: 2}}>
                            <InputItem type='date' title='排查开始日期' isSearch property='checkDateFrom'/>
                            <InputItem type='date' title='排查结束日期' isSearch property='checkDateTo'/>
                        </Row>
                        <span style={{flex: 2}}/>
                    </Row>
                </Form>
                <DangerActionDelayApplyEdit
                    controller={me.state.controller}
                    visible={me.state.editVisible}
                    data={me.state.current}
                    onOk={me.state.controller.handleOk}
                    onCancel={me.state.controller.handleCancel}
                />
                {
                    this.state.imageVisible && <ImageView visible={this.state.imageVisible} imagePath={this.state.imagePath} onCancel={()=>{
                        this.setState({imageVisible: false});
                    }}/>
                }
                <Table {...renderProps} />
            </Resizable>
        );
    }
}

export default DangerActionDelayApplyList;
