
import React from 'react';
import { Modal, Form, Row, Col } from 'antd';
import InputItem from './../../components/inputitem';
import SelectItem from '../../components/selectitem';
import TableUtils from '../../components/tableutils';

const ExamineDetailEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;
    return (
        <Modal
            visible={visible}
            centered={true}
            title='检测明细'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            scroll={ {y: true }}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <SelectItem title='检查路径' data={data} method='examinePath' property='examinePathId' textProperty='examinePathName'/>
                <SelectItem title='检查内容' data={data} method='examineContent' property='examineContentId' textProperty='examineContentName'/>
                <SelectItem title='负责人' data={data} method='user' property='dutyUserId' textProperty='dutyUserName'/>
                <InputItem type='checkbox' title='达标' property='targeted'/>
                <InputItem type='checkbox' title='需整改' property='needDangerCheck'/>
                <InputItem type='dateTime' title='检查时间' property='checkTime'/>
                <InputItem type='date' title='整改期限' property='actionLimitDate'/>
                <InputItem type='dateTime' title='隐患排查日期' property='dangerCheckDate'/>
                <InputItem type='textarea' title='备注' property='remark'/>
                <InputItem type='number' title='顺序' property='sort'/>
            </Form>
        </Modal>
    );
};

export default ExamineDetailEdit;
