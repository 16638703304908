import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class ExamineDirectionStore extends BaseStore {
    method = 'examineDirection';
}

const examineDirectionStore = new ExamineDirectionStore();
export default examineDirectionStore;
