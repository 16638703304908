import React from 'react';
import { Modal, Form } from 'antd';
import InputItem from '../../components/inputitem';
import TableUtils from './../../components/tableutils';

const ExamineTypeEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;

    return (
        <Modal
            visible={visible}
            title='检查类型'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <InputItem title='编号' property='code' required/>
                <InputItem title='名称' property='name' required/>
                <InputItem type='number' title='顺序' property='sort'/>
                <InputItem type='checkbox' title='可用' property='usable'/>
            </Form>
        </Modal>
    );
};

export default ExamineTypeEdit;
