import React, {Component} from 'react';
import PrivateRoute from './assets/components/privateroute';
import {Route, Switch} from 'react-router-dom';
import Logon from './assets/pages/logons/logon';
import Index from './assets/pages/indexes/index';
import Home from './assets/pages/homes/home';
import './style.less';


class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact={true} path='/home' component={Home}/>
        <Route exact={true} path='/logon' component={Logon}/>
        <PrivateRoute path='/' component={Index}/>
      </Switch>
    );
  }
}

export default App;