import React from 'react';
import { Modal, Form } from 'antd';
import InputItem from '../../components/inputitem';
import TableUtils from './../../components/tableutils';
import SysUtils from '../../components/sysutils';

const MenuEdit = (props) => {
    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;
    return (
        <Modal
            visible={visible}
            title='菜单编辑'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <InputItem title='标题' property='name' required/>
                <InputItem title='KEY' property='menuKey' required/>
                <InputItem title='图标' property='iconName'/>
                <InputItem type='textarea' title='路径' property='url'/>
                <InputItem type='number' title='顺序' property='sort'/>
                <InputItem type='checkbox' title='可用' property='usable'/>
            </Form>
        </Modal>
    );
};

export default MenuEdit;
