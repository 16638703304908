import React from 'react';
import { Modal, Form } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from '../../components/selectitem';
import TableUtils from '../../components/tableutils';

const UserEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;
    return (
        <Modal
            visible={visible}
            title='用户管理'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <InputItem title='用户名' property='userName' required/>
                <InputItem title='姓名' property='name' required/>
                <InputItem title='英文名' property='englishName' required/>
                <InputItem title='邮箱' property='email'/>
                <InputItem title='手机' property='mobilePhone'/>
                <InputItem title='电话' property='telephone'/>
                <SelectItem title='部门' data={data} method='department' property='departmentId' textProperty='departmentName'/>
                <SelectItem title='班组' data={data} method='team' property='teamId' textProperty='teamName'/>
                <SelectItem title='角色' data={data} method='role' property='roleId' textProperty='roleName'/>
                <InputItem type='number' title='顺序' property='sort'/>
                <InputItem type='checkbox' title='可用' property='usable'/>
            </Form>
        </Modal>
    );
};

export default UserEdit;
