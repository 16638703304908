import React, {useState} from 'react';
import { Modal, Form, Row } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from '../../components/selectitem';
import TableUtils from '../../components/tableutils';

const DangerActionDelayApplyEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;

    const [state, setState] = useState({});

    return (
        <Modal
            visible={visible}
            title='整改延期申请'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <InputItem type='date' title='整改期限' property='actionLimitDate'/>
                <InputItem type='textarea' title='延迟原因' property='delayReason'/>
            </Form>
        </Modal>
    );
};

export default DangerActionDelayApplyEdit;
