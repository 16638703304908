import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class ExamineContentStore extends BaseStore {
    method = 'examineContent';
}

const examineContentStore = new ExamineContentStore();
export default examineContentStore;
