import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class DepartmentStore extends BaseStore {
    method = 'department';
}

const departmentStore = new DepartmentStore();
export default departmentStore;
