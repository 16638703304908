import React from 'react';
import { Modal, Form } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from '../../components/selectitem';
import SysUtils from '../../components/sysutils';
import RequestUtils from '../../components/requestutils';
import TableUtils from '../../components/tableutils';
import Toast from '../../components/toast';

const UserPasswordEdit = (props) => {

    const { visible, onClose, controller, data } = props;
    const [form] = Form.useForm();
    controller.passwordEditForm = form;

    return (
        <Modal
            visible={visible}
            title='密码修改'
            okText='修改'
            cancelText='取消'
            maskClosable={false}
            onCancel={onClose}
            onOk={e=>{
                form.validateFields().then(values => {
                    if (SysUtils.isEmpty(values.newPassword)) {
                        Toast.error('新密码不能为空');
                    } else if (values.newPassword !== values.rePassword) {
                        Toast.error('两次输入的密码不同,请重新输入');
                    } else {
                        const params = {
                            userId: data.id,
                            oldPassword: values.oldPassword,
                            newPassword: values.newPassword
                        };
                        RequestUtils.execute('user/editPassword', params, result=>{
                            if (!result.result) {
                                Toast.error(result.message);
                            } else {
                                form.resetFields();
                                Toast.success('密码修改成功');
                                onClose && onClose(e);
                            }
                        });
                    }
                });
            }}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <InputItem title='姓名' readOnly={true} property='name'/>
                <InputItem type='password' title='原密码' property='oldPassword'/>
                <InputItem type='password' title='新密码' property='newPassword'/>
                <InputItem type='password' title='确认密码' property='rePassword'/>
            </Form>
        </Modal>
    );
};

export default UserPasswordEdit;
