import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class FeedbackTypeStore extends BaseStore {
    method = 'enum/feedbackType';
}

const feedbackTypeStore = new FeedbackTypeStore();
export default feedbackTypeStore;
