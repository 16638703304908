import React from 'react';
import './../../../style.less';
import { Table, Divider, Tooltip, Upload } from 'antd';
import Resizable from 'react-component-resizable';
import Lightbox from 'react-image-lightbox';
import ToolBar from '../../components/toolbar';
import DataLoading from '../../components/dataloading';
import SysUtils from '../../components/sysutils';
import TableUtils from '../../components/tableutils';
import RequestUtils from '../../components/requestutils';
import BaseController from '../../components/basecontroller';
import Icon from './../../components/icon';
import DeviceEdit from './deviceedit';
import Toast from '../../components/toast';
import ImageView from './../../components/imageview';

class DeviceList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editVisible: false,
            imageVisible: false,
            imagePath: '',
            controller: new BaseController(this, 'device'),
            data: null,
            contentHeight: 100,
            currentPage: 1,
            pageSize: 20,
            current: null
        };
        const uploadprops = (record) => {
            return {
                accept: 'image/jpeg',
                beforeUpload: file => {
                    const parmas = {
                        id: record.id,
                        domain: 'Device',
                        imagePath: record.imagePath
                    };
                    RequestUtils.uploadFile('imageFunction/pcUploadImage', parmas, file, result=>{
                        if (result.result) {
                            record.imagePath = result.data;
                        }
                        result.result ? Toast.success('图片上传成功') : Toast.error('图片上传失败');
                    });
                },
                showUploadList: false
            };
        };
        const fontSize = '14px';
        this.columns = [
            { dataIndex: 'deviceTypeName', title: '巡检类型', align: 'center', width: 80 },
            { dataIndex: 'tag', title: 'TAG号', align: 'center', width: 80 },
            { dataIndex: 'name', title: '名称', align: 'center', width: 200 },
            { dataIndex: 'imagePath', title: '图片', align: 'center', width: 80, render: (cellData, rowData)=>{
                return SysUtils.isEmpty(cellData) ? <span>无图片</span> : <span className='blue pointer' onClick={e=>{
                    this.setState({
                        imageVisible: true,
                        imagePath: cellData
                    });
                }}>查看图片</span>;
            } },
            { dataIndex: 'equipmentName', title: '巡检装置', align: 'center', width: 80 },
            { dataIndex: 'partName', title: '巡检区域', align: 'center', width: 80 },
            { dataIndex: 'minReading', title: '最小读数', align: 'center', width: 80 },
            { dataIndex: 'maxReading', title: '最大读数', align: 'center', width: 80 },
            { dataIndex: 'unitName', title: '单位', align: 'center', width: 80 },
            { dataIndex: 'sort', title: '顺序', align: 'center', width: 65 },
            { dataIndex: 'usable', title: '可用', align: 'center', width: 65, render: TableUtils.boolFormat },
            {},
            {
                title: '数据操作',
                align: 'center',
                width: 110,
                fixed: 'right',
                key: 'operation',
                render: (text, record) => (
                    <div style={{ width: 110 }}>
                        <Tooltip placement='top' title='编辑'>
                            <span style={{ cursor: 'pointer' }} onClick={e => {
                                this.state.controller.edit(record);
                            }}>
                                <Icon type='edit' style={{ color: '#1890ff', fontSize: fontSize }} />
                            </span>
                        </Tooltip>
                        <Divider type='vertical' />
                        <Tooltip placement='top' title='删除'>
                            <span style={{ cursor: 'pointer' }} onClick={e => {
                                this.state.controller.delete(record);
                            }}>
                                <Icon type='delete' style={{ color: '#FF3D3D', fontSize: fontSize }} />
                            </span>
                        </Tooltip>
                        <Divider type='vertical' />
                        <Upload {...uploadprops(record)}>
                            <Tooltip placement='top' title='上传图片'>
                                <span style={{ cursor: 'pointer' }}>
                                    <Icon type='upload' style={{ color: '#1890ff', fontSize: fontSize }} />
                                </span>
                            </Tooltip>
                        </Upload>
                    </div>
                )
            }
        ];
    }

    componentDidMount() {
        this.state.controller.load();
    }

    render() {
        if (this.state.data === null) {
            return <DataLoading />;
        }
        const me = this;
        const data = this.state.data;
        const renderProps = {
            className: 'fixedWidthTable',
            bordered: true,
            scroll: { y: me.state.contentHeight - TableUtils.getTableUsedHeight(me.state) },
            pagination: TableUtils.getPagination(me.state.controller, me.state),
            columns: me.columns,
            size: 'small',
            dataSource: data.datas,
            title: () => {
                return TableUtils.getActionBar(me.state.controller);
            }
        };
        return (
            <Resizable style={{ height: SysUtils.getContentHeight() }} onResize={e => {
                this.setState({
                    contentWidth: e.width,
                    contentHeight: e.height
                });
            }}>
                <DeviceEdit
                    controller={me.state.controller}
                    visible={me.state.editVisible}
                    data={me.state.current}
                    onOk={me.state.controller.handleOk}
                    onCancel={me.state.controller.handleCancel}
                />
                {
                    this.state.imageVisible && <ImageView visible={this.state.imageVisible} imagePath={this.state.imagePath} onCancel={()=>{
                        this.setState({imageVisible: false});
                    }}/>
                }
                <Table {...renderProps} />
            </Resizable>
        );
    }
}

export default DeviceList;
