import React, {useState} from 'react';
import { Modal, Form } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from './../../components/selectitem';
import TableUtils from './../../components/tableutils';
import SysUtils from './../../components/sysutils';

const CompanyEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;

    const [state, setState] = useState({});

    return (
        <Modal
            visible={visible}
            title='公司信息'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <InputItem title='编号' property='code' required/>
                <InputItem title='简称' property='shortName' required/>
                <InputItem title='全称' property='name' required/>
                <InputItem title='组织机构代码' property='orgCode'/>
                <SelectItem title='省' data={data} method='region' property='provinceId' filterKey={0} textProperty='provinceName' onChange={value=>{
                    data.provinceId = value;
                    data.cityId = 0;
                    data.cityName = '';
                    data.districtId = 0;
                    data.districtName = '';
                    form.setFieldsValue({
                        cityId: 0,
                        cityName: '',
                        districtId: 0,
                        districtName: ''
                    });
                    setState({});
                }}/>
                <SelectItem title='市' data={data} method='region' property='cityId' filterKey={SysUtils.isEmpty(data) ? 0 : data.provinceId} textProperty='cityName' onChange={value=>{
                    data.cityId = value;
                    data.districtId = 0;
                    data.districtName = '';
                    form.setFieldsValue({
                        districtId: 0,
                        districtName: ''
                    });
                    setState({});
                }}/>
                <SelectItem title='区' data={data} method='region' property='districtId' filterKey={SysUtils.isEmpty(data) ? 0 : data.cityId} textProperty='districtName'/>
                <InputItem title='公司地址' property='address'/>
                <InputItem title='联系人' property='contact'/>
                <InputItem title='联系方式' property='phoneNumber'/>
                <InputItem title='电子邮箱' property='email'/>
                <InputItem title='传真' property='fax'/>
                <InputItem type='number' title='顺序' property='sort'/>
                <InputItem type='checkbox' title='可用' property='usable'/>
            </Form>
        </Modal>
    );
};

export default CompanyEdit;
