import React from 'react';
import { Modal, List, Button } from 'antd';
import InputItem from '../../components/inputitem';
import SelectItem from '../../components/selectitem';
import TableUtils from '../../components/tableutils';
import { Scrollbars } from 'react-custom-scrollbars';

const CompanySelect = (props) => {

    const { visible, onCancel, onOk, companys } = props;

    return (
        <Modal
            visible={visible}
            title='公司选择'
            okText='保存'
            cancelText='取消'
            closable={false}
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
            width={400}
        >
            <Scrollbars autoHide hideTracksWhenNotNeeded={true} style={{height: 300, width: 360}}>
                <List
                    bordered
                    dataSource={companys}
                    style={{cursor: 'pointer'}}
                    renderItem={item => <List.Item onClick={e=>{
                        onOk(item);
                    }}>{item.name}</List.Item>}
                    />
            </Scrollbars>
        </Modal>
    );
};

export default CompanySelect;