
import React from 'react';
import { Modal, Form, Row, Col } from 'antd';
import InputItem from './../../components/inputitem';
import SelectItem from '../../components/selectitem';
import TableUtils from '../../components/tableutils';

const ExamineDirectionPathContentEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;
    return (
        <Modal
            visible={visible}
            centered={true}
            title='检查内容'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            scroll={ {y: true }}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <SelectItem title='检查内容' data={data} method='examineContent' property='examineContentId' textProperty='examineContentName' required/>
                <InputItem type='number' title='顺序' property='sort'/>
            </Form>
        </Modal>
    );
};

export default ExamineDirectionPathContentEdit;
