
import React from 'react';
import { Modal, Form, Row, Col } from 'antd';
import InputItem from './../../components/inputitem';
import SelectItem from '../../components/selectitem';
import TableUtils from '../../components/tableutils';

const PatrolPlanDetailEdit = (props) => {

    const { visible, onCancel, onOk, controller, data } = props;
    const [form] = Form.useForm();
    controller.form = form;
    return (
        <Modal
            visible={visible}
            centered={true}
            title='计划明细'
            okText='保存'
            cancelText='取消'
            maskClosable={false}
            scroll={ {y: true }}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form form={form} {...TableUtils.formItemLayout()}>
                <SelectItem title='巡检装置' data={data} method='equipment' property='equipmentId' textProperty='equipmentName' required/>
                <SelectItem title='巡检区域' data={data} method='part' property='partId' textProperty='partName' required/>
                <InputItem type='number' title='顺序' property='sort'/>
            </Form>
        </Modal>
    );
};

export default PatrolPlanDetailEdit;
