import BaseStore from './basestore';
import RequestUtils from '../components/requestutils';
import SysUtils from '../components/sysutils';

class DangerSourceStore extends BaseStore {
    method = 'dangerSource';
}

const dangerSourceStore = new DangerSourceStore();
export default dangerSourceStore;
